import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import en from './../../../assets/i18n/en.json'
import ar from './../../../assets/i18n/ar.json';
import { DOCUMENT } from '@angular/common';
const languageKey = '__lang';
@Injectable({
  providedIn: 'root',
})
export class LangService {
  isSingleLang = false;
  renderer: Renderer2;
  defaultLanguage = localStorage.getItem(languageKey) || 'en';
  supportedLanguages: Language[] = [
    { code: 'en', direction: 'ltr', label: 'English', shorthand: 'en' },
    { code: 'ar', direction: 'rtl', label: 'Arabic', shorthand: 'ar' },
  ];
  constructor(
    private translate: TranslateService,
    private rendererFactory: RendererFactory2,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.init()
  }
  init() {
    this.translate.setTranslation('en', en);
    this.translate.setTranslation('ar', ar);
    this.translate.setDefaultLang(this.defaultLanguage);
    if (this.isSingleLang) {
      this.translate.use(this.defaultLanguage);
      this.changeCssFile(this.defaultLanguage);
    } else {
      this.language = '';
    }
  }
  checkForDirectionChange() {
    this.renderer.removeClass(document.body, 'ltr');
    this.renderer.removeClass(document.body, 'rtl');
    this.renderer.addClass(document.body, this.direction);
    this.renderer.setAttribute(
      document.documentElement,
      'direction',
      this.direction
    );
  }
  set language(lang: string) {
    let language = lang || localStorage.getItem(languageKey);
    const isSupportedLanguage = this.supportedLanguages
      .map((item) => item.code)
      .includes(language);
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }
    if (
      lang !== '' &&
      this.supportedLanguages.map((item) => item.code).includes(lang) &&
      this.direction !==
        this.supportedLanguages.find((item) => item.code === lang).direction
    ) {
      localStorage.setItem(languageKey, lang);
      window.location.reload();
    } else {
      this.translate.use(language);
    }
    this.checkForDirectionChange();
    this.changeCssFile(language);
    localStorage.setItem(languageKey, language);
  }
  changeCssFile(lang: string) {
    let headTag = this.document.getElementsByTagName(
      'head'
    )[0] as HTMLHeadElement;
    let existingLink = this.document.getElementById(
      'langCss'
    ) as HTMLLinkElement;
    let bundleName = lang === 'ar' ? 'arabicStyle.css' : 'englishStyle.css';
    if (existingLink) {
      existingLink.href = bundleName;
    } else {
      let newLink = this.document.createElement('link');
      newLink.rel = 'stylesheet';
      newLink.type = 'text/css';
      newLink.id = 'langCss';
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }
  }
  get language(): string {
    return this.translate.currentLang;
  }
  get languageShorthand(): string {
    return this.supportedLanguages.find(
      (item) => item.code === this.translate.currentLang
    ).shorthand;
  }
  get direction(): string {
    return this.supportedLanguages.find(
      (item) => item.code === this.translate.currentLang
    ).direction;
  }
  get languageLabel(): string {
    return this.supportedLanguages.find(
      (item) => item.code === this.translate.currentLang
    ).label;
  }
}
export class Language {
  code: string;
  direction: string;
  label: string;
  shorthand: string;
}
