<ul class="navbar-nav sub-nav">
    <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="profileDropdown" role="button" aria-haspopup="true"
            aria-expanded="false">
            <svg viewBox="0 0 1 1" width="20" height="20" class="" [ngClass]="scrolled ? 'fill-black':'fill-white'">
                <path
                    d="M.45 0C.4.02.36.05.33.09.29.16.29.25.33.31c.055.08.15.11.235.08C.625.37.67.32.69.26.7.24.7.22.7.2V.17A.199.199 0 00.64.055.2.2 0 00.545 0H.45m.035.1C.45.11.43.13.41.16c0 0 0 .01-.005.02C.4.18.4.19.4.2.4.215.4.22.405.23.41.26.44.29.48.3h.05C.54.3.55.29.56.285.565.28.58.27.58.26.59.25.6.24.6.23V.2.17C.59.155.585.14.57.13.56.12.54.11.53.1H.485M.46.5C.41.51.37.52.32.54a.336.336 0 00-.075.03.531.531 0 00-.235.37C0 .95 0 .975 0 .99V1h1V.98A.523.523 0 00.82.62.45.45 0 00.54.5H.46m.01.1C.42.61.37.62.33.64a.43.43 0 00-.2.22C.13.87.12.9.12.9h.76V.88A.404.404 0 00.72.67.297.297 0 00.55.605C.54.6.48.6.47.6M0 1V.99 1"
                    fill-rule="evenodd"></path>
            </svg>
        </a>
        <div class="dropdown-menu" aria-labelledby="profileDropdown" #dropdownmenu>
            <div class="navdown-div">
                <div class="container" *ngIf="logged != '1'">
                    <div class="row" *ngIf="registerDesigner == false">
                        <div class="col-md-6 login order-md-1 px-5">
                            <div class="mb-3 upper col-md-12 mb-3 l0">
                                {{ 'navbar.signin' | translate }}
                            </div>
                            <div class="mb-3 col-md-12 mb-3 loggg l0">
                                {{'navbar.type_email_pass' | translate}}
                            </div>
                            <form (ngSubmit)="onSubmit()" #loginForm="ngForm" autocomplete="off">
                                <div class="col-md-12 mb-3 l0 loggg">
                                    <label for="firstName">
                                        {{ 'navbar.email_username' | translate }}
                                    </label>
                                    <input class="form-control" type="text" name="email" ngModel #Email="ngModel"
                                        autocomplete="off" email placeholder="{{'register_page.email' | translate}}"
                                        required [ngClass]="{'is-invalid':Email.touched && Email.invalid }">
                                    <div *ngIf="Email.touched && Email.invalid" class="invalid-feedback">
                                        <div *ngIf="Email.errors.required">
                                            {{'register_page.emailRequired' | translate}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mb-3 l0 loggg">
                                    <label for="lastName">
                                        {{ 'navbar.password' | translate }}
                                    </label>
                                    <input class="form-control pass-input" type="{{ isVisible ? 'password' : 'text' }}"
                                        name="password" [(ngModel)]="model['password']" #Password="ngModel"
                                        autocomplete="off" placeholder="{{'register_page.password' | translate}}"
                                        required [ngClass]="{'is-invalid':Password.touched && Password.invalid }">
                                    <i [ngClass]="isVisible ? 'fa-eye' : 'fa-eye-slash' " class="fas visible-icon"
                                        (click)="isVisible = !isVisible" aria-hidden="true"></i>
                                    <div *ngIf="Password.touched && Password.invalid" class="invalid-feedback">
                                        <div *ngIf="Password.errors.required">
                                            {{'register_page.passwordRequired' | translate}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 l0 loggg">
                                    <div *ngIf="loginError"
                                        style="color:#dc3545; font-size: 1.2em; margin-bottom: 10px;">
                                        {{loginError}}
                                    </div>
                                    <a class="forget" (click)="forgetPassword()" style="cursor: pointer;">
                                        {{ 'navbar.forget_pass' | translate }}
                                    </a>
                                </div>
                                <div class="col-md-6 l0 loggg">
                                    <div class="d-flex justify-content-end">
                                        <span style="margin-top: 10px; margin-right: 10px;" *ngIf="loading">{{
                                            'navbar.loading' | translate
                                            }}</span>
                                        <button class="btn btn-dark rounded-0 px-5" type="button" type="submit"
                                            [disabled]="loginForm.invalid">
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            {{ 'navbar.signin' | translate }}
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-12 mb-3 l0 loggg">
                                    <hr class="ahr">
                                </div>
                                <div class="col-md-12 mb-3 l0 loggg"
                                    style="text-align:left; margin-top: 0px;font: 12px/20px 'Styrene Medium',Helvetica,Arial,Sans-serif; font-weight: normal !important;">
                                    {{'navbar.dont_have_account' | translate}}
                                    <a class="loggg forget"
                                        style="margin-left: 20px; font-weight: normal !important; display: inline;"
                                        routerLink="/register/user" (click)="HideDropdownMenu()">
                                        {{'navbar.register' | translate}}
                                    </a>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-6 order-md-2 mb-4  parent-of-register">
                            <h4 class="row">
                                <div class="w-100 text-muted text-center">
                                    <p class="mb-3 upper col-md-12 mb-3 l0">
                                        {{'navbar.join_us_as_a_brand' | translate}}
                                    </p>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <button class="btn btn-dark px-5 rounded-0" style="color: #fff;"
                                        (click)="registerDesigner = !registerDesigner">
                                        {{'navbar.apply_now' | translate}}
                                    </button>
                                </div>
                            </h4>
                        </div>
                    </div>
                    <div class="row" *ngIf="registerDesigner == true">
                        <div class="col-md-12">
                            <div class="register-popup-in-navbar">
                                <h6 class="brand-title"> {{'navbar.brandtitle' | translate}}</h6>
                                <i class="fa fa-times fa-lg" (click)="registerDesigner = !registerDesigner"></i>
                                <form #brandRegister="ngForm" (ngSubmit)="registerSubmit()">
                                    <div class="form-group">
                                        <h5> {{'navbar.major' | translate}} </h5>
                                        <div class="row">
                                            <div class="col-md-4" *ngFor="let type of designerTypes ; let i = index">
                                                <label for="type_answer_{{type.name}}" class="label-major"
                                                    [ngClass]="isChecked == type.id ? 'active' :''">
                                                    {{type.name}}</label>
                                                <input type="radio" id="type_answer_{{type.name}}" name="designer_type"
                                                    class="d-none" [value]=type.id [(ngModel)]="isChecked" required
                                                    #type="ngModel">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-3 col-6">
                                                <label> {{'register_page.firstName' | translate}} *
                                                </label>
                                                <input type="text" class="form-control" name="f_name_en" ngModel
                                                    required #firstName="ngModel"
                                                    [ngClass]="{'is-invalid':firstName.touched && firstName.invalid }">
                                                <div *ngIf="firstName.touched && firstName.invalid"
                                                    class="invalid-feedback">
                                                    <div *ngIf="firstName.errors.required">
                                                        {{'register_page.firstNameRequired' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-6">
                                                <label> {{'register_page.firstName' | translate}}
                                                    ({{'navbar.lang' | translate}}) </label>
                                                <input type="text" class="form-control" name="f_name_ar" ngModel
                                                     #firstNamen="ngModel"
                                                    [ngClass]="{'is-invalid':firstNamen.touched && firstNamen.invalid }">
                                                <div *ngIf="firstNamen.touched && firstNamen.invalid"
                                                    class="invalid-feedback">
                                                    <div *ngIf="firstNamen.errors.required">
                                                        {{'register_page.firstNameRequired' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-6">
                                                <label> {{'register_page.lastName' | translate}} *
                                                </label>
                                                <input type="text" class="form-control" name="l_name_en" ngModel
                                                    required #lastName="ngModel"
                                                    [ngClass]="{'is-invalid':lastName.touched && lastName.invalid }">
                                                <div *ngIf="lastName.touched && lastName.invalid"
                                                    class="invalid-feedback">
                                                    <div *ngIf="lastName.errors.required">
                                                        {{'register_page.lastNameRequired' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-6">
                                                <label> {{'register_page.lastName' | translate}}
                                                    ({{'navbar.lang' | translate}})  </label>
                                                <input type="text" class="form-control" name="l_name_ar" ngModel
                                                    #lastNamen="ngModel"
                                                    [ngClass]="{'is-invalid':lastNamen.touched && lastNamen.invalid }">
                                                <div *ngIf="lastNamen.touched && lastNamen.invalid"
                                                    class="invalid-feedback">
                                                    <div *ngIf="lastNamen.errors.required">
                                                        {{'register_page.lastNameRequired' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-4 col-6">
                                                <label> {{'register_page.email' | translate}} * </label>
                                                <input type="email" class="form-control"
                                                    placeholder="{{'register_page.email' | translate}}" name="email"
                                                    ngModel #email="ngModel" autocomplete="off" required email
                                                    [ngClass]="{'is-invalid':email.touched && email.invalid }">
                                                <div *ngIf="email.submitted && email.invalid" class="invalid-feedback">
                                                    <div *ngIf="email.errors.required">
                                                        {{'register_page.emailRequired' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-6">
                                                <label> {{'register_page.phone' | translate}} *</label>
                                                <input type="text" class="form-control"
                                                    placeholder="{{'register_page.phone' | translate}}" name="phone"
                                                    ngModel required maxlength="10" #phone="ngModel"
                                                    [ngClass]="{'is-invalid':phone.touched && phone.invalid }">
                                                <div *ngIf="phone.touched && phone.invalid" class="invalid-feedback">
                                                    <div *ngIf="phone.errors.required">
                                                        {{'register_page.phoneRequired' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-6">
                                                <label> {{'register_page.insta' | translate}} </label>
                                                <input type="text" class="form-control" placeholder="@" name="instagram"
                                                    ngModel>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-12">
                                                <button type="submit" class="btn btn-dark rounded-0 px-5"
                                                    [disabled]="brandRegister.invalid">
                                                    <span *ngIf="loading" class="spinner-border spinner-border-sm"
                                                        role="status" aria-hidden="true"></span>
                                                    {{'register_page.register' | translate}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" *ngIf="logged == '1'">
                    <div class="row" style="min-height: 417px; width: 100%;">
                        <div class="col-md-12 order-md-1 px-5">
                            <div style="margin: 17% auto auto;">
                                <div class="w-100 text-muted text-center ">
                                    <h4 class="mb-3 upper col-md-12 mb-3 l0">
                                        {{'navbar_section.user_profile' | translate}}
                                    </h4>
                                </div>
                                <div class="w-100"></div>
                                <div class="w-100 d-flex justify-content-center">
                                    <a (click)="HideDropdownMenu()" routerLink="/account/overview"
                                        class="btn btn-dark px-5 rounded-0"
                                        style="margin: 0 5px; color: #FFF !important;">
                                        {{'navbar_section.profile' | translate}}
                                    </a>
                                    <a (click)="logout()" class="btn btn-dark px-5 rounded-0"
                                        style="margin: 0 5px; color: #FFF !important;">
                                        {{'navbar_section.logout' | translate}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </li>
    <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="favDropdown" role="button" aria-haspopup="true" aria-expanded="false">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" class=""
                [ngClass]="scrolled ? 'fill-black':'fill-white'" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 67.949 67.949" style="enable-background:new 0 0 67.949 67.949;"
                xml:space="preserve">
                <g>
                    <path d="M62.49,11.239c-7.39-7.388-19.412-7.388-26.8,0l-1.716,1.715l-1.715-1.715c-7.388-7.389-19.411-7.389-26.799,0
                        c-7.238,7.238-7.285,18.711-0.109,26.688c6.545,7.273,25.848,22.986,26.667,23.651c0.556,0.452,1.225,0.672,1.89,0.672
                        c0.022,0,0.044,0,0.065-0.001c0.688,0.032,1.381-0.204,1.955-0.671c0.819-0.665,20.124-16.378,26.671-23.652
                        C69.774,29.95,69.727,18.477,62.49,11.239z M58.139,33.913c-5.103,5.669-19.13,17.361-24.166,21.51
                        c-5.036-4.148-19.06-15.839-24.162-21.509C4.805,28.35,4.758,20.426,9.702,15.482c2.525-2.524,5.841-3.787,9.157-3.787
                        s6.632,1.262,9.157,3.787l3.772,3.772c0.449,0.449,1.015,0.717,1.609,0.811c0.964,0.207,2.01-0.062,2.76-0.81l3.774-3.773
                        c5.051-5.049,13.267-5.048,18.315,0C63.19,20.426,63.143,28.35,58.139,33.913z" />
                </g>
            </svg>
            <span id="bagCounter">
                {{myFavoriteList?.length}}
            </span>
        </a>
        <div class="dropdown-menu" aria-labelledby="favDropdown" #dropdownmenu>
            <div class="navdown-div">
                <p class="m-l-100 f-s-21 container">
                    {{'navbar.ur_favorite' | translate}}
                </p>
                <div class="fav-list">
                    <ul class="container justify-content-start" *ngIf="myFavoriteList">
                        <li class="fav-list-style" *ngFor="let product of myFavoriteList">
                            <div class="card border-none">
                                <a class="fav" [routerLink]="['/products/product', product.id]"
                                    (click)="HideDropdownMenu()">
                                    <img [src]="imgBase+product.images[0]" alt="">
                                </a>
                                <blockquote class="card-blockquote pt-1">
                                    <div class="favourites-product-container">
                                        <i *ngIf="product.isFavourite == true" style="color: #000;" class="fas fa-heart"
                                            (click)="removeFromFavourit(product)"></i>
                                    </div>
                                    <div class="product-card-title" [routerLink]="['/products/product', product.id]"
                                        (click)="HideDropdownMenu()">
                                        <p>
                                            <b> {{product.name}}</b>
                                        </p>
                                    </div>
                                </blockquote>
                            </div>
                        </li>
                    </ul>
                </div>
                <p class="m-l-100 f-s-16 Dropdown_viewMore container" *ngIf="logged == '1'">
                    <a routerLink="/account/favorite" (click)="HideDropdownMenu()">
                        {{'navbar.all_favorites' | translate}}
                    </a>
                </p>
            </div>
        </div>
    </li>
    <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="cartDropdown" role="button" aria-haspopup="true" aria-expanded="false"
            routerLink="/cart" (click)="HideDropdownMenu()">
            <svg class="" [ngClass]="scrolled ? 'fill-black':'fill-white'" viewBox="0 0 1 1" width="20" height="20">
                <path
                    d="M.48 0a.238.238 0 00-.09.025.285.285 0 00-.14.185V.3H0V1h1V.3H.75V.26C.75.22.75.2.74.17A.236.236 0 00.62.03C.6.02.57.01.54 0H.48m.01.1a.17.17 0 00-.13.1C.35.22.35.23.35.27V.3h.3V.27.21A.193.193 0 00.58.12.239.239 0 00.49.1M0 .65V.475.65m.1 0V.9h.8V.4H.1v.25"
                    fill-rule="evenodd"></path>
            </svg>
            <span id="bagCounter">
                {{CARTITEMS.length}}
            </span>
        </a>
        <div class="dropdown-menu" aria-labelledby="cartDropdown" #dropdownmenu>
            <div class="navdown-div">
                <p class="m-l-100 f-s-21 container">
                    {{'navbar.ur_cart' | translate}}
                </p>
                <ul class="container justify-content-start">
                    <li *ngFor="let item of CARTITEMS;let i = index" class="fav-list-style m-2">
                        <div style="max-height: 320px;" *ngIf="item.Product && i < 4"
                            [routerLink]="[ '/products/product/', item.Product.id ]" (click)="HideDropdownMenu()">
                            <a class="fav">
                                <img [src]="imgBase+item.Product.images[0]" alt="">
                            </a>
                        </div>
                        <p *ngIf="item.Product && i < 4"><a class="fav"
                                [routerLink]="[ '/products/product/', item.Product.id ]" (click)="HideDropdownMenu()">
                                {{item.Product.name }} </a>
                        </p>
                    </li>
                </ul>
                <p class="m-l-100 f-s-16 Dropdown_viewMore go-cart">
                    <a routerLink="/cart" (click)="HideDropdownMenu()">
                        {{'navbar.ur_cart' | translate}}
                    </a>
                </p>
            </div>
        </div>
    </li>
    <li class="nav-item dropdown">
        <a class="lang-anchor" (click)="onLanguageChange('ar')" *ngIf="currentLanguage == 'en'">
            عربي
        </a>
        <a class="lang-anchor" (click)="onLanguageChange('en')" *ngIf="currentLanguage == 'ar'">
            English
        </a>
    </li>
    <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="searchDropdown" role="button" aria-haspopup="true"
            aria-expanded="false">
            <svg viewBox="0 0 1 1" width="20" height="20" class="" [ngClass]="scrolled ? 'fill-black':'fill-white'">
                <title>search</title>
                <path
                    d="M.45 0a.486.486 0 00-.4.26.53.53 0 00-.02.39c.02.05.05.1.09.14.06.08.15.13.24.15.04.01.07.01.105.015A.489.489 0 00.78.85V.845L.86.92C.9.965.94 1 .94 1L1 .94S.965.9.92.86C.88.82.85.78.85.78s0-.01.01-.01C.91.69.94.61.95.515V.48C.95.44.95.42.945.39A.493.493 0 00.77.105.433.433 0 00.51 0H.45m0 .09C.38.1.32.12.26.16A.375.375 0 00.1.41C.09.43.09.45.09.48c0 .04 0 .06.01.09.03.11.11.205.21.255C.35.84.39.86.44.86h.075A.39.39 0 00.86.53V.42A.392.392 0 00.6.11C.57.1.55.1.52.09H.45"
                    fill-rule="evenodd" />
            </svg>
        </a>
        <div class="dropdown-menu" aria-labelledby="searchDropdown" #dropdownmenu>
            <div class="navdown-div">
                <div class="container search-field">
                    <div class="row w-100">
                        <div class="col-12">
                            <form #searchForm="ngForm" autocomplete="off" (keyup.enter)="search()">
                                <input name="search" id="search" type="text" [(ngModel)]="model['search']"
                                    class="form-control search-head" autocomplete="off"
                                    placeholder="{{'navbar.search' | translate}}">
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </li>
</ul>
<ngx-snackbar [position]="'top-right'"></ngx-snackbar>
