import { Component, OnInit } from '@angular/core';
import { CartService } from './../../../services/cart/cart.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-shipping-step-one',
  templateUrl: './shipping-step-one.component.html',
  styleUrls: ['./shipping-step-one.component.scss'],
})
export class ShippingStepOneComponent implements OnInit {
  data: [];
  User = {};
  constructor(private service: CartService, private router: Router) {}
  ngOnInit(): void {
    this.getUserData();
  }
  getUserData() {
    if (!sessionStorage.getItem('user')) {
      this.router.navigateByUrl('/cart');
    } else {
      this.User = JSON.parse(sessionStorage.getItem('user'));
    }
  }
  onSubmit() {
      if (this.User['title'] == '') {
          alert("Title is required!");
      } else if (this.User['full_name'] == '') {
          alert("First name is required!");
      } else if (this.User['last_name'] == '') {
          alert("Last name is required!");
      } else if (this.User['phone'] == '') {
        alert("Phone no is required!");
      } else if (this.User['address'] == '') {
        alert("Address 1 is required!");
      } else if (this.User['city_id'] == '') {
        alert("City is required!");
      } else if (this.User['post_code'] == '') {
          alert("Postcode is required!");
      } else {
        this.service.edit_profile(this.User).subscribe((res) => {
              this.router.navigateByUrl('cart/secure-payment');
        });
      }
  }
}
