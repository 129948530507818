<div class="cart">
    <div class="shopping-bag">
        <div class="container">
            <h2 class="h2 shopping-bag-title text-uppercase"> {{'cart.Shopping_bag'|translate}} </h2>
            <!-- <p class="shopping-bag-brief">
                If you are registered with My Account, you will also be asked for your password.
            </p> -->
            <div class="shopping-bag-body">
                <div class="row">
                    <div class="col-12 col-lg-9">
                        <div class="left-side">
                            <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" autocomplete="off"
                                class="custom-confirm">
                                <div class="form-group">
                                    <div class="email">
                                        <label> {{ 'navbar.email_username' | translate }} * </label>
                                        <input class="form-control" type="text" name="email"
                                            [(ngModel)]="model['email']" #email="ngModel" autocomplete="off"
                                            placeholder="{{'register_page.email' | translate}}" required email
                                            [ngClass]="{'is-invalid':email.touched && email.invalid }">
                                        <div *ngIf="email.touched && email.invalid" class="invalid-feedback">
                                            <div *ngIf="email.errors.required"> {{'register_page.emailRequired' |
                                                translate}} </div>
                                        </div>
                                    </div>
                                    <div class="password mt-3 mb-2">
                                        <label> {{ 'navbar.password' | translate }} * </label>
                                        <input class="form-control pass-input" type="password" name="password"
                                            [(ngModel)]="model['password']" #password="ngModel" autocomplete="off"
                                            placeholder="{{'register_page.password' | translate}}" required
                                            [ngClass]="{'is-invalid':password.touched && password.invalid }">
                                        <div *ngIf="password.touched  && password.invalid" class="invalid-feedback">
                                            <div *ngIf="password.errors.required"> {{'register_page.passwordRequired' |
                                                translate}}
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="loginError" style="color:#dc3545; font-size: 1.2em; margin-top: 1em;">
                                        {{loginError}}
                                    </div>
                                    <div class="col-md-6 l0 loggg">
                                        <a class="forget" (click)="forgetPassword()" style="cursor: pointer;">
                                            {{ 'navbar.forget_pass' | translate }}
                                        </a>
                                    </div>
                                    <div class="col-md-12 mb-3 l0 loggg"
                                        style="text-align:left; margin-top: 0px;font: 12px/20px 'Styrene Medium',Helvetica,Arial,Sans-serif; font-weight: normal !important;">
                                        {{'navbar.dont_have_account' | translate}}
                                        <a class="loggg forget"
                                            style="margin-left: 20px; font-weight: normal !important; display: inline;"
                                            routerLink="/register/user">
                                            {{'navbar.register' | translate}}
                                        </a>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-dark text-uppercase float-right rounded-0">
                                    {{'navbar.signin'|translate}}
                                </button>
                            </form>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <app-cart-aside></app-cart-aside>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>