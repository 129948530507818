import { AccountService } from 'src/app/services/account/account.service';
import { environment } from 'src/environments/environment.prod';
import { LangService } from './../../../services/lang/lang.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-designers-favorite-list',
  templateUrl: './designers-favorite-list.component.html',
  styleUrls: ['./designers-favorite-list.component.scss'],
})
export class DesignersFavoriteListComponent implements OnInit {
  brandsFavoriteList: Array<any> = [];
  websiteLang = '';
  activeClassMenu = 'designers-favorite';
  favoriteStatus;
  public sessionStorage = sessionStorage;
  imgBase = environment.imgURL;
  constructor(
    private router: Router,
    private translate: LangService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService
  ) {
    if (sessionStorage.getItem('logged') != '1') {
      this.router.navigateByUrl('/');
    }
    this.websiteLang = this.translate.language;
  }
  ngOnInit() {
    this.getFavourits();
  }
  getFavourits() {
    this.spinner.show('sp');
    this.accountService.getDesignerFavourit().subscribe((res: any) => {
      this.brandsFavoriteList = res.data;
      this.spinner.hide('sp');
    });
  }
  removeDesignerFromFav(id) {
    const data = {
      id: id,
      like: 0,
    };
    this.accountService.setDesignerFavourit(data).subscribe((res) => {
      this.getFavourits();
    });
  }
}
