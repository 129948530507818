<div class="user-register">
    <div class="container">
        <div class="row justify-content-center">
            <h3 class="text-muted border-bottom pb-2">
                {{'register_page.register' | translate}}
            </h3>
            <div class="w-100"></div>
            <div class="col-md-6">
                <form name="form" (ngSubmit)="onSubmit()" #registerForm="ngForm">
                    <div class="form-group">
                        <label for="formGroupExampleInput"> {{'register_page.title' | translate}} </label>
                        <input type="text" name="title" ngModel #title="ngModel" autocomplete="off"
                            [ngClass]="{'is-invalid':title.touched && title.invalid }" id="titleOfUser"
                            style="display: none;">
                        <select class="custom-select form-control" name="title" ngModel #title="ngModel"
                            placeholder="select an option">
                            <option value="Ms">Ms</option>
                            <option value="Mr">Mr</option>
                        </select>
                        <div *ngIf="title.touched && title.invalid" class="invalid-feedback">
                            <div *ngIf="title.errors.required"> {{'register_page.titleRequired' | translate}} </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput2">
                            {{'register_page.firstName' | translate}} </label>
                        <input class="form-control" type="text" name="first_name" ngModel #firstName="ngModel"
                            autocomplete="off" placeholder="{{'register_page.firstName' | translate}}"
                            [ngClass]="{'is-invalid':firstName.touched && firstName.invalid }">
                        <div *ngIf="firstName.touched && firstName.invalid" class="invalid-feedback">
                            <div *ngIf="firstName.errors.required"> {{'register_page.firstNameRequired' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput2">
                            {{'register_page.lastName' | translate}}
                        </label>
                        <input class="form-control" type="text" name="last_name" ngModel #lastName="ngModel"
                            autocomplete="off" placeholder="{{'register_page.lastName' | translate}}"
                            [ngClass]="{'is-invalid':lastName.touched && lastName.invalid }">
                        <div *ngIf="lastName.touched && lastName.invalid" class="invalid-feedback">
                            <div *ngIf="lastName.errors.required"> {{'register_page.lastNameRequired' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput2">
                            {{'register_page.userName' | translate}}
                        </label>
                        <input class="form-control" type="text" name="name" ngModel #userName="ngModel"
                            autocomplete="off" placeholder="{{'register_page.userName' | translate}}"
                            [ngClass]="{'is-invalid':userName.touched && userName.invalid }">
                        <div *ngIf="userName.touched && userName.invalid" class="invalid-feedback">
                            <div *ngIf="userName.errors.required"> {{'register_page.userNameRequired' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput2">{{'register_page.email' | translate}} *</label>
                        <input class="form-control" type="email" name="email" ngModel #email="ngModel"
                            autocomplete="off" placeholder="{{'register_page.email' | translate}}" required email
                            [ngClass]="{'is-invalid':email.touched && email.invalid }">
                        <div *ngIf="email.touched && email.invalid" class="invalid-feedback">
                            <div *ngIf="email.errors.required"> {{'register_page.emailRequired' | translate}} </div>
                        </div>
                    </div>
                    <div class="form-group" style="position: relative;">
                        <label for="formGroupExampleInput2">
                            {{'register_page.password' | translate}} *
                        </label>
                        <input class="form-control pass-input register-password"
                            type="{{ isVisible ? 'password' : 'text' }}" name="password" [(ngModel)]="model['password']"
                            #password="ngModel" autocomplete="off"
                            placeholder="{{'register_page.password' | translate}}" required
                            [ngClass]="{'is-invalid':password.touched && password.invalid }">
                        <div *ngIf="password.touched && password.invalid" class="invalid-feedback">
                            <div *ngIf="password.errors.required"> {{'register_page.passwordRequired' | translate}}
                            </div>
                        </div>
                        <i class="fas fa-eye form-control" (click)="showPass()"
                            style="padding-left: 16px; padding-right: 16px; padding-top: 10px; position: relative; width: 50px;position: absolute;top: 23px;right: 0;"
                            aria-hidden="true"></i>
                        <i class="fas fa-eye-slash form-control" (click)="hiddenPass()"
                            style="padding-left: 16px; padding-top: 10px; position: relative; width: 50px;float: right;margin-top: -38px;"
                            aria-hidden="true" [ngClass]="{'d-none' : isVisible}"></i>
                        <app-password-strength-bar [passwordToCheck]="model['password']" [barLabel]="barLabel">
                        </app-password-strength-bar>
                    </div>
                    <div class="form-group w-100" style=" margin-bottom: 2rem;">
                        <label for="formGroupExampleInput">{{'register_page.country' | translate}} </label>
                        <input type="text" name="country_id" ngModel #Contry="ngModel" autocomplete="off"
                            [ngClass]="{'is-invalid':Contry.touched && Contry.invalid }" style="display: none;">
                        <select class="form-control" data-placeholder="Select an option" name="country_id"
                            [(ngModel)]="model['country_id']" #Contry="ngModel" autocomplete="off"
                            [ngClass]="{'is-invalid':Contry.touched && Contry.invalid }">
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Aland Islands">Aland Islands</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Curaçao">Curaçao</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern and Antarctic Lands">French Southern and Antarctic Lands</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard and Mc Donald Islands">Heard and Mc Donald Islands</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran">Iran</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Ivory Coast">Ivory Coast</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea(North Korea)">Korea(North Korea)</option>
                            <option value="Korea(South Korea)">Korea(South Korea)</option>
                            <option value="Kosovo">Kosovo</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao PDR">Lao PDR</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia">Micronesia</option>
                            <option value="Moldova">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestine">Palestine</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion Island">Reunion Island</option>
                            <option value="Romania">Romania</option>
                            <option value="Russian">Russian</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Barthelemy">Saint Barthelemy</option>
                            <option value="Saint Helena">Saint Helena</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Martin (French part)">Saint Martin (French part)</option>
                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option selected value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and the South Sandwich">South Georgia and the South Sandwich</option>
                            <option value="South Sudan">South Sudan</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-Leste">Timor-Leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="US Minor Outlying Islands">US Minor Outlying Islands</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican City">Vatican City</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vietnam">Vietnam</option>
                            <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                            <option value="Wallis and Futuna Islands">Wallis and Futuna Islands</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput2">{{'register_page.city' | translate}} </label>
                        <input class="form-control" type="text" name="city" ngModel #city="ngModel" autocomplete="off"
                            placeholder="{{'register_page.city' | translate}}"
                            [ngClass]="{'is-invalid':city.touched && city.invalid }">
                        <div *ngIf="city.touched && city.invalid" class="invalid-feedback">
                            <div *ngIf="city.errors.required"> {{'register_page.cityRequired' | translate}} </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput2">{{'register_page.address1' | translate}}</label>
                        <input class="form-control" type="text" name="address" ngModel #address1="ngModel"
                            autocomplete="off" placeholder="{{'register_page.address1' | translate}}"
                            [ngClass]="{'is-invalid':address1.touched && address1.invalid }">
                        <div *ngIf="address1.touched && address1.invalid" class="invalid-feedback">
                            <div *ngIf="address1.errors.required"> {{'register_page.address1Required' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput2">{{'register_page.address2' | translate}}</label>
                        <input class="form-control" type="text" name="address2" ngModel #address2="ngModel"
                            autocomplete="off" placeholder="{{'register_page.address2' | translate}}"
                            [ngClass]="{'is-invalid':address2.touched && address2.invalid }">
                    </div>
                    <div class="form-group">
                        <div>
                            <label for="formGroupExampleInput2">{{'register_page.phone' | translate}} *</label>
                        </div>
                        <input type="text" name="country_code" ngModel #countryCode="ngModel" autocomplete="off"
                            [ngClass]="{'is-invalid':countryCode.touched && countryCode.invalid }" id="codeCountry"
                            style="display: none;">
                        <select #countryCode type="text" list="browsers" required="" name="country_code"
                            style="width: 18%; float: left; direction: ltr;height: 41px;" class="form-control "
                            id="formGroupExampleInput2" aria-label="Text input with dropdown button"
                            [(ngModel)]="model['country_code']" class="form-control countryCodeClass"
                            name="country_code" ngModel autocomplete="off" required maxlength="10"
                            [ngClass]="{'is-invalid':countryCode.touched && countryCode.invalid }">

                            <option value="+0">+0</option>
                            <option value="+1">+1</option>
                            <option value="+7">+7</option>
                            <option value="+20">+20</option>
                            <option value="+27">+27</option>
                            <option value="+30">+30</option>
                            <option value="+31">+31</option>
                            <option value="+32">+32</option>
                            <option value="+33">+33</option>
                            <option value="+34">+34</option>
                            <option value="+36">+36</option>
                            <option value="+38">+38</option>
                            <option value="+39">+39</option>
                            <option value="+39">+39</option>
                            <option value="+40">+40</option>
                            <option value="+41">+41</option>
                            <option value="+43">+43</option>
                            <option value="+44">+44</option>
                            <option value="+44">+44</option>
                            <option value="+44">+44</option>
                            <option value="+44">+44</option>
                            <option value="+44">+44</option>
                            <option value="+45">+45</option>
                            <option value="+46">+46</option>
                            <option value="+47">+47</option>
                            <option value="+47">+47</option>
                            <option value="+48">+48</option>
                            <option value="+49">+49</option>
                            <option value="+51">+51</option>
                            <option value="+52">+52</option>
                            <option value="+53">+53</option>
                            <option value="+54">+54</option>
                            <option value="+55">+55</option>
                            <option value="+56">+56</option>
                            <option value="+57">+57</option>
                            <option value="+58">+58</option>
                            <option value="+60">+60</option>
                            <option value="+61">+61</option>
                            <option value="+61">+61</option>
                            <option value="+61">+61</option>
                            <option value="+62">+62</option>
                            <option value="+63">+63</option>
                            <option value="+64">+64</option>
                            <option value="+65">+65</option>
                            <option value="+66">+66</option>
                            <option value="+70">+70</option>
                            <option value="+81">+81</option>
                            <option value="+82">+82</option>
                            <option value="+84">+84</option>
                            <option value="+86">+86</option>
                            <option value="+90">+90</option>
                            <option value="+91">+91</option>
                            <option value="+92">+92</option>
                            <option value="+93">+93</option>
                            <option value="+94">+94</option>
                            <option value="+95">+95</option>
                            <option value="+98">+98</option>
                            <option value="+211">+211</option>
                            <option value="+212">+212</option>
                            <option value="+212">+212</option>
                            <option value="+213">+213</option>
                            <option value="+216">+216</option>
                            <option value="+218">+218</option>
                            <option value="+220">+220</option>
                            <option value="+221">+221</option>
                            <option value="+222">+222</option>
                            <option value="+223">+223</option>
                            <option value="+224">+224</option>
                            <option value="+225">+225</option>
                            <option value="+226">+226</option>
                            <option value="+227">+227</option>
                            <option value="+228">+228</option>
                            <option value="+229">+229</option>
                            <option value="+230">+230</option>
                            <option value="+231">+231</option>
                            <option value="+232">+232</option>
                            <option value="+233">+233</option>
                            <option value="+234">+234</option>
                            <option value="+235">+235</option>
                            <option value="+236">+236</option>
                            <option value="+237">+237</option>
                            <option value="+238">+238</option>
                            <option value="+239">+239</option>
                            <option value="+240">+240</option>
                            <option value="+241">+241</option>
                            <option value="+242">+242</option>
                            <option value="+242">+242</option>
                            <option value="+244">+244</option>
                            <option value="+245">+245</option>
                            <option value="+246">+246</option>
                            <option value="+248">+248</option>
                            <option value="+249">+249</option>
                            <option value="+250">+250</option>
                            <option value="+251">+251</option>
                            <option value="+252">+252</option>
                            <option value="+253">+253</option>
                            <option value="+254">+254</option>
                            <option value="+255">+255</option>
                            <option value="+256">+256</option>
                            <option value="+257">+257</option>
                            <option value="+258">+258</option>
                            <option value="+260">+260</option>
                            <option value="+261">+261</option>
                            <option value="+262">+262</option>
                            <option value="+263">+263</option>
                            <option value="+264">+264</option>
                            <option value="+265">+265</option>
                            <option value="+266">+266</option>
                            <option value="+267">+267</option>
                            <option value="+268">+268</option>
                            <option value="+269">+269</option>
                            <option value="+269">+269</option>
                            <option value="+290">+290</option>
                            <option value="+291">+291</option>
                            <option value="+297">+297</option>
                            <option value="+298">+298</option>
                            <option value="+299">+299</option>
                            <option value="+350">+350</option>
                            <option value="+351">+351</option>
                            <option value="+352">+352</option>
                            <option value="+353">+353</option>
                            <option value="+354">+354</option>
                            <option value="+355">+355</option>
                            <option value="+356">+356</option>
                            <option value="+357">+357</option>
                            <option value="+358">+358</option>
                            <option value="+359">+359</option>
                            <option value="+370">+370</option>
                            <option value="+371">+371</option>
                            <option value="+372">+372</option>
                            <option value="+373">+373</option>
                            <option value="+374">+374</option>
                            <option value="+375">+375</option>
                            <option value="+376">+376</option>
                            <option value="+377">+377</option>
                            <option value="+378">+378</option>
                            <option value="+380">+380</option>
                            <option value="+381">+381</option>
                            <option value="+385">+385</option>
                            <option value="+386">+386</option>
                            <option value="+387">+387</option>
                            <option value="+389">+389</option>
                            <option value="+420">+420</option>
                            <option value="+421">+421</option>
                            <option value="+423">+423</option>
                            <option value="+500">+500</option>
                            <option value="+501">+501</option>
                            <option value="+502">+502</option>
                            <option value="+503">+503</option>
                            <option value="+504">+504</option>
                            <option value="+505">+505</option>
                            <option value="+506">+506</option>
                            <option value="+507">+507</option>
                            <option value="+508">+508</option>
                            <option value="+509">+509</option>
                            <option value="+590">+590</option>
                            <option value="+591">+591</option>
                            <option value="+592">+592</option>
                            <option value="+593">+593</option>
                            <option value="+594">+594</option>
                            <option value="+595">+595</option>
                            <option value="+596">+596</option>
                            <option value="+597">+597</option>
                            <option value="+598">+598</option>
                            <option value="+599">+599</option>
                            <option value="+670">+670</option>
                            <option value="+672">+672</option>
                            <option value="+672">+672</option>
                            <option value="+673">+673</option>
                            <option value="+674">+674</option>
                            <option value="+675">+675</option>
                            <option value="+676">+676</option>
                            <option value="+677">+677</option>
                            <option value="+678">+678</option>
                            <option value="+679">+679</option>
                            <option value="+680">+680</option>
                            <option value="+681">+681</option>
                            <option value="+682">+682</option>
                            <option value="+683">+683</option>
                            <option value="+684">+684</option>
                            <option value="+686">+686</option>
                            <option value="+687">+687</option>
                            <option value="+688">+688</option>
                            <option value="+689">+689</option>
                            <option value="+690">+690</option>
                            <option value="+691">+691</option>
                            <option value="+692">+692</option>
                            <option value="+850">+850</option>
                            <option value="+852">+852</option>
                            <option value="+853">+853</option>
                            <option value="+855">+855</option>
                            <option value="+856">+856</option>
                            <option value="+880">+880</option>
                            <option value="+886">+886</option>
                            <option value="+960">+960</option>
                            <option value="+961">+961</option>
                            <option value="+962">+962</option>
                            <option value="+963">+963</option>
                            <option value="+964">+964</option>
                            <option value="+965">+965</option>
                            <option selected value="+966">+966</option>
                            <option value="+967">+967</option>
                            <option value="+968">+968</option>
                            <option value="+970">+970</option>
                            <option value="+971">+971</option>
                            <option value="+972">+972</option>
                            <option value="+973">+973</option>
                            <option value="+974">+974</option>
                            <option value="+975">+975</option>
                            <option value="+976">+976</option>
                            <option value="+977">+977</option>
                            <option value="+992">+992</option>
                            <option value="+994">+994</option>
                            <option value="+995">+995</option>
                            <option value="+996">+996</option>
                            <option value="+998">+998</option>
                            <option value="+1242">+1242</option>
                            <option value="+1246">+1246</option>
                            <option value="+1264">+1264</option>
                            <option value="+1268">+1268</option>
                            <option value="+1284">+1284</option>
                            <option value="+1340">+1340</option>
                            <option value="+1345">+1345</option>
                            <option value="+1441">+1441</option>
                            <option value="+1473">+1473</option>
                            <option value="+1649">+1649</option>
                            <option value="+1664">+1664</option>
                            <option value="+1670">+1670</option>
                            <option value="+1671">+1671</option>
                            <option value="+1684">+1684</option>
                            <option value="+1758">+1758</option>
                            <option value="+1767">+1767</option>
                            <option value="+1784">+1784</option>
                            <option value="+1787">+1787</option>
                            <option value="+1809">+1809</option>
                            <option value="+1868">+1868</option>
                            <option value="+1869">+1869</option>
                            <option value="+1876">+1876</option>
                            <option value="+7370">+7370</option>
                        </select>
                        <input class="form-control mobile-num" type="text" name="phone" ngModel #phone="ngModel"
                            autocomplete="off" placeholder="{{'register_page.phone' | translate}}"
                            maxlength="10" [ngClass]="{'is-invalid':phone.touched && phone.invalid }"
                            style="width: 82%;">
                        <div *ngIf="phone.touched && phone.invalid" class="invalid-feedback">
                            <div *ngIf="phone.errors.required"> {{'register_page.phoneRequired' | translate}} </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput2">{{'register_page.postCode' | translate}} </label>
                        <input class="form-control" type="text" name="post_code" ngModel #postCode="ngModel"
                            autocomplete="off" placeholder="{{'register_page.postCode' | translate}}"
                            [ngClass]="{'is-invalid':postCode.touched && postCode.invalid }">
                        <div *ngIf="postCode.touched && postCode.invalid" class="invalid-feedback">
                            <div *ngIf="postCode.errors.required"> {{'register_page.postCodeRequired' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="w-100"></div>
                    <div>
                        <p *ngIf="EmailOrUsernameExists != ''" style="color: rgb(202, 19, 19); margin-bottom: 20px;">
                            {{EmailOrUsernameExists}}
                        </p>
                    </div>
                    <div class="justify-content-center">
                        <button class="btn btn-dark px-5 rounded-0 m-auto" type="submit" id="apply"
                            [disabled]="registerForm.invalid"> Sign Up
                            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>



<!-- [End Password Modal] -->
<ngx-snackbar [position]="'top-right'">
</ngx-snackbar>
