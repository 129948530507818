import { NgForm } from '@angular/forms';
import { SizeGuideComponent } from './size-guide/size-guide.component';
import { ShowHomeComponent } from './show-home/show-home.component';
import { ShareProductComponent } from './share-product/share-product.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LangService } from './../../../services/lang/lang.service';
import {
    Component,
    OnInit,
    ChangeDetectorRef,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { ProductsService } from 'src/app/services/products/products.service';
import { NgbCollapse, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from 'src/app/services/cart/cart.service';
import { AccountService } from 'src/app/services/account/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';
@Component({
    selector: 'app-product-view',
    templateUrl: './product-view.component.html',
    styleUrls: ['./product-view.component.scss'],
})
export class ProductViewComponent implements OnInit {
    ViewBagLoder = false;
    ViewBagPopup = false;
    BagItem: SubProduct;
    imgBase = environment.imgURL;
    @ViewChild('size_collapse', { static: false }) size_collapse: NgbCollapse;
    @ViewChild('color_collapse', { static: false }) color_collapse: NgbCollapse;
    FaqFormShow = false;
    sizeCollapsed = true;
    colorCollapsed = true;
    websiteLang = '';
    model: any = {};
    loading = false;
    mainImages = [];
    Colors: Color[] = [];
    Sizes: Size[] = [];
    selectedColor: Color = new Color();
    selectedSize: Size = new Size();
    selectedSubProduct: SubProduct = new SubProduct();
    Designer: any = {};
    SubProducts: any = [];
    productData = {} as any;
    proudctId: number;
    logged = sessionStorage.getItem('logged');
    @ViewChild('faq') faq: NgForm;
    addloading: boolean;
    constructor(
        private cartService: CartService,
        private cdr: ChangeDetectorRef,
        private translate: LangService,
        private route: ActivatedRoute,
        private productsService: ProductsService,
        private spinner: NgxSpinnerService,
        private accountService: AccountService,
        private modalService: NgbModal
    ) {
        this.websiteLang = this.translate.language;
    }
    ngOnInit() {
        this.selectedColor = new Color();
        this.selectedSize = new Size();
        this.selectedSubProduct = new SubProduct();
        this.BagItem = new SubProduct();
        this.model['quantity'] = 1;
        this.route.paramMap.subscribe((params) => {
            this.proudctId = +params.get('id')!;
            this.getProductData();
        });
    }
    getProductData() {
        this.spinner.show('sp');
        this.productsService.getSingleProduct(this.proudctId).subscribe((res) => {
            this.spinner.hide('sp');
            this.productData = res;
            if (this.logged != '1') {
                this.accountService.favouritProducts.forEach((element) => {
                    if (element.id == this.productData.id) {
                        this.productData.isFavourite = true;
                    }
                });
            }
            this.Designer = this.productData.designer;
            this.SubProducts = this.productData.sub_products;
            this.mainImages = [...this.productData['images']];
            this.Colors = [];
            this.Sizes = [];
            if (!this.productData['show_color'] && !this.productData['show_size']) {
                this.selectedSubProduct = this.SubProducts[0];
                this.selectedColor = new Color();
                this.selectedSize = new Size();
            } else {
                this.SubProducts.forEach((sub) => {
                    if (sub['color']) {
                        const found = this.Colors.includes(sub['color']);
                        if (!found) {
                            this.Colors = [...this.Colors, sub['color']];
                        }
                    }
                    this.selectColor(0, true);
                });
            }

            console.log(this.selectedSubProduct);
            console.log(this.selectedColor);
            console.log(this.selectedSize);

        });
    }
    arrayOne(n) {
        n = Number(n);
        let arr = [];
        for (let index = 1; index <= n; index++) {
            arr.push(index);
        }
        return arr;
    }
    selectColor(index, size_select = false) {
        this.selectedColor = this.Colors[index];
        this.Sizes = [];
        this.SubProducts.forEach((sub) => {
            if (sub['color']['id'] == this.selectedColor.id) {
                if (sub['size']) {
                    const Sfound = this.Sizes.includes(sub['size']);
                    if (!Sfound) {
                        this.Sizes = [...this.Sizes, sub['size']];
                    }
                }
            }
        });
        if (size_select) {
            this.selectSize(0);
        } else {
            this.selectedSize = new Size();
            this.selectedSubProduct = new SubProduct();
        }
    }
    selectSize(index) {

        if (this.Sizes[index]) {
            this.selectedSize = this.Sizes[index];
        }

        this.SubProducts.forEach((sub) => {
            if (
                sub['color'] && sub['color']['id'] == this.selectedColor.id &&
                sub['size'] && sub['size']['id'] == this.selectedSize.id
            ) {
                this.selectedSubProduct = sub;
            }
        });

    }
    openSizeCollapse() {
        if (!this.colorCollapsed) {
            this.color_collapse.toggle(false);
        }
        this.size_collapse.toggle();
    }
    openColorCollapse() {
        if (!this.sizeCollapsed) {
            this.size_collapse.toggle(false);
        }
        this.color_collapse.toggle();
    }
    quantityChange() {
        if (this.model['quantity'] == '' || this.model['quantity'] == null) {
            this.model['quantity'] = 1;
            this.cdr.markForCheck();
        }
    }
    AddToBag() {
        let date = {
            carancy: this.selectedSubProduct['currency_id'],
            product_id: this.productData['id'],
            quantity: this.model['quantity'],
            sub_product_id: this.selectedSubProduct['id'],
            size_id: this.selectedSize.id,
            price_id:
                Number(this.selectedSubProduct['price']) *
                Number(this.model['quantity']),
            note: '',
        };
        this.ViewBagLoder = true;
        this.cartService.AddToCart(date).subscribe((res) => {
            this.ViewBagLoder = false;
            if (res['status']) {
                this.ViewBagPopup = true;
                this.BagItem = { ...this.selectedSubProduct };
                setTimeout(() => {
                    this.ViewBagPopup = false;
                    this.BagItem = new SubProduct();
                }, 5000);
                this.cdr.markForCheck();
            }
            this.cartService.cartDetails();
        });
    }
    addToFavourit(productId) {
        if (this.logged == '1') {
            const data = {
                id: productId,
                like: 1,
            };
            this.accountService.addFavourits(data).subscribe((res) => {
                this.getProductData();
                this.accountService.favDetails();
            });
        } else {
            this.productData.isFavourite = true;
            this.productData = { ...this.productData };
            this.accountService.favouritProducts.push(this.productData);
            localStorage.setItem(
                'favouritProducts',
                JSON.stringify(this.accountService.favouritProducts)
            );
        }
    }
    removeFromFavourit(productId) {
        if (this.logged == '1') {
            const data = {
                id: productId,
                like: 0,
            };
            this.accountService.addFavourits(data).subscribe((res) => {
                this.getProductData();
                this.accountService.favDetails();
            });
        } else {
            this.productData.isFavourite = false;
            this.productData = { ...this.productData };
            const index = this.accountService.favouritProducts.findIndex(
                (p) => p.id == this.productData.id
            );
            this.accountService.favouritProducts.splice(index, 1);
            console.log(this.accountService.favouritProducts);
            localStorage.setItem(
                'favouritProducts',
                JSON.stringify(this.accountService.favouritProducts)
            );
        }
    }
    shareItem(productData) {
        const modalRef = this.modalService.open(ShareProductComponent, {
            size: 'lg',
            centered: true,
            keyboard: false,
            windowClass: '',
        });
        modalRef.componentInstance.productData = productData;
    }
    showHome(productData) {
        const modalRef = this.modalService.open(ShowHomeComponent, {
            size: 'lg',
            centered: true,
            keyboard: false,
            windowClass: '',
        });
        modalRef.componentInstance.productData = productData;
        modalRef.componentInstance.selectedSubProduct = this.selectedSubProduct;
        modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {

            this.BagItem = { ...this.selectedSubProduct };
            if (productData.show_at_home_price) {
                this.BagItem['price'] = productData.show_at_home_price;
            }
            this.ViewBagPopup = receivedEntry;
            setTimeout(() => {
                this.ViewBagPopup = false;
            }, 5000);
            this.cdr.markForCheck();
        });
    }
    sizeGuid(productData) {
        const modalRef = this.modalService.open(SizeGuideComponent, {
            centered: true,
            keyboard: false,
            windowClass: '',
        });
        modalRef.componentInstance.productData = productData;
    }
    createQuestion() {
        this.addloading = true;
        this.faq.value.id = this.proudctId;
        this.productsService.addQuestion(this.faq.value).subscribe((res) => {
            console.log(res);
            this.addloading = false;
            this.faq.reset();
            this.getProductData();
            this.FaqFormShow = true;
        });
    }
}
class Color {
    category_id: string = '';
    color: string = '__';
    id: string = '';
    image: string = '';
}
class Size {
    category_id: string = '';
    id: string = '';
    size: string = '__';
}
class SubProduct {
    category_id: string = '';
    color: Color = new Color();
    created_at: string = '';
    created_by: string = '';
    currency_id: string = '';
    description: string = '';
    description_ar: string = '';
    id: string = '';
    images: string[] = [];
    img: string = '';
    name_ar: string = '';
    price: string = '';
    product_id: string = '';
    quantity: string = '1';
    size: Size = new Size();
}
