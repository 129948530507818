import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart-navbar',
  templateUrl: './cart-navbar.component.html',
  styleUrls: ['./cart-navbar.component.scss']
})
export class CartNavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
