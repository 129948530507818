import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyRoutingModule } from './survey-routing.module';

import { HowComponent } from './how/how.component';
import { WhatComponent } from './what/what.component';
import { WhereComponent } from './where/where.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        HowComponent,
        WhatComponent,
        WhereComponent
    ],
    imports: [
        CommonModule,
        SurveyRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
})
export class SurveyModule { }
