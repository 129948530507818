import { AccountService } from './../../../services/account/account.service';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.scss'],
})
export class AccountOverviewComponent implements OnInit {
  userData = {} as any;
  myFavoriteList;
  websiteLang = '';
  myCart: any[] = [];
  imgBase = environment.imgURL;
  brandsFavoriteList: Array<any> = [];
  constructor(private router: Router, private accountService: AccountService) {
    if (!sessionStorage.getItem('user')) {
      this.router.navigateByUrl('/');
    }
  }
  ngOnInit() {
    this.getAccInfo();
    this.getAllOrders();
    this.getFavProducts();
    this.getFavourits();
  }
  getFavourits() {
    this.accountService.getDesignerFavourit().subscribe((res: any) => {
      this.brandsFavoriteList = res.data;
    });
  }
  getFavProducts() {
    this.accountService.getFavourits().subscribe((res: any) => {
      this.myFavoriteList = res.data;
    });
  }
  getAccInfo() {
    this.accountService.getProfileInfo().subscribe((res: any) => {
      this.userData = res.message;
    });
  }
  getAllOrders() {
    this.accountService.getOrders().subscribe((res: any) => {
      this.myCart = res;
      this.myCart.forEach((element) => {
        while (element.id.length < 6) element.id = '0' + element.id;
      });
      this.myCart = [...this.myCart];
    });
  }
}
