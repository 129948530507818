import { AccountService } from 'src/app/services/account/account.service';
import { SnackbarService } from 'ngx-snackbar';
import { AuthService } from './../../../services/auth/auth.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  @ViewChild('changePassword') changePassword: NgForm;
  @ViewChild('changePassModal') changePassModal: ElementRef;
  @ViewChild('editProfile') editProfile: NgForm;
  @ViewChild('newAddress') newAddress: NgForm;
  userData = {} as any;
  closeResult: string;
  loading: boolean;
  addresses: any[];
  newAdd: boolean = false;
  defloading:boolean;
  addloading:boolean;
  constructor(
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private accountService: AccountService
  ) {}
  ngOnInit() {
    this.getProfileData();
    this.getAddress();
  }
  getProfileData() {
    this.accountService.getProfileInfo().subscribe((res: any) => {
      this.userData = res.message;
    });
  }
  submitEditing() {
    this.loading = true;
    this.editProfile.value.title = this.userData.title;
    this.editProfile.value.name = this.userData.name;
    this.accountService.editProfileInfo(this.editProfile.value).subscribe(
      (res: any) => {
        this.loading = false;
        this.getProfileData();
        if (res['status'] == true) {
          this.snackbarService.add({
            msg: res.message,
            timeout: 4000,
            background: '#eee',
            color: '#333',
            action: {
              text: 'x',
              color: 'red',
            },
          });
        }
      },
      (error) => {
        this.snackbarService.add({
          msg: error.message,
          timeout: 4000,
          background: '#eee',
          color: '#333',
          action: {
            text: 'x',
            color: 'red',
          },
        });
      }
    );
  }
  changePass() {
    this.authService
      .changePassword(this.changePassword.value)
      .subscribe((res: any) => {
        this.changePassword.reset();
        this.changePassModal.nativeElement.click();
        if (res['status'] == true) {
          this.snackbarService.add({
            msg: res.message,
            timeout: 4000,
            background: '#eee',
            color: '#333',
            action: {
              text: 'x',
              color: 'red',
            },
          });
          // this.toastr.success('Password Updated !');
        } else if (res['status'] == false) {
          this.snackbarService.add({
            msg: res.message,
            timeout: 4000,
            background: '#eee',
            color: '#333',
            action: {
              text: 'x',
              color: 'red',
            },
          });
          // this.toastr.error(res.message);
        }
      });
  }
  getAddress() {
    this.accountService.getAddress().subscribe((res: any) => {
      this.addresses = res.message;
    });
  }
  addAddress() {
    this.addloading = true;
    this.accountService
      .addNewAddress(this.newAddress.value)
      .subscribe((res) => {
        this.addloading = false;
        this.newAdd = false;
        this.newAddress.reset();
        this.getAddress();
      });
  }
  makeDefult(id) {
    this.defloading = true;
    this.accountService.defultAddress(id).subscribe((res) => {
      this.defloading = false;
      this.getAddress();
    });
  }
  removeAdd(id) {
    this.defloading = true;
    this.accountService.deleteAddress(id).subscribe((res) => {
      this.getAddress();
      this.defloading = false;
    });
  }
}
