import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}
  loginUser(data) {
    const body = new HttpParams()
      .set('email', data.email)
      .set('password', data.password);
    return this.http.post(environment.baseURL + `/User/login`, body);
  }
  resgisterUser(data) {
    const body = new HttpParams()
      .set('title', data.title)
      .set('first_name', data.first_name)
      .set('last_name', data.last_name)
      .set('name', data.name)
      .set('email', data.email)
      .set('password', data.password)
      .set('country_id', data.country_id)
      .set('city', data['city'])
      .set('address', data['address'])
      .set('address2', data['address2'])
      .set('country_code', data.country_code)
      .set('phone', data['phone'])
      .set('post_code', data['post_code']);
    return this.http.post(environment.baseURL + `/User/register`, body);
  }
  forgetPassword(data) {
    const body = new HttpParams().set('email', data.email);
    return this.http.post(environment.baseURL + `/User/forget_password`, body);
  }
  changePassword(data) {
    const body = new HttpParams()
      .set('user_id', sessionStorage.getItem('userId'))
      .set('old_password', data.old_password)
      .set('new_password', data.new_password);
    return this.http.post(environment.baseURL + `/User/change_password`, body);
  }
  usernameExsit(data) {
    const body = new HttpParams().set('username', data.name);
    return this.http.post(
      environment.baseURL + `/User/check_username_exist`,
      body
    );
  }
  emailExsit(data) {
    const body = new HttpParams().set('email', data.email);
    return this.http.post(
      environment.baseURL + `/User/check_email_exist`,
      body
    );
  }
  registerBrand(data) {
    const body = new HttpParams()
      .set('email', data.email)
      .set('phone', data.phone)
      .set('instagram', data.instagram)
      .set('designer_type', data.designer_type)
      .set('f_name_en', data.f_name_en)
      .set('f_name_ar', data.f_name_ar)
      .set('l_name_en', data.f_name_en)
      .set('l_name_ar', data.f_name_ar)
    return this.http.post(environment.baseURL + `/home/add_designer`, body);
  }
}
