import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from './../../../services/account/account.service';
import { environment } from 'src/environments/environment.prod';
import { LangService } from './../../../services/lang/lang.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-favorite-list',
  templateUrl: './favorite-list.component.html',
  styleUrls: ['./favorite-list.component.scss'],
})
export class FavoriteListComponent implements OnInit {
  myFavoriteList: Array<any> = [];
  websiteLang = '';
  activeClassMenu = 'wishlist';
  favoriteStatus;
  public sessionStorage = sessionStorage;
  imgBase = environment.imgURL;
  constructor(
    private router: Router,
    private translate: LangService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService
  ) {
    if (sessionStorage.getItem('logged') != '1') {
      this.router.navigateByUrl('/');
    }
    this.websiteLang = this.translate.language;
  }
  ngOnInit() {
    this.getFavProducts();
  }
  getFavProducts() {
    this.spinner.show('sp');
    this.accountService.getFavourits().subscribe((res: any) => {
      this.myFavoriteList = res.data;
      this.spinner.hide('sp');
    });
  }
  removeProductFromFav(id) {
    const data = {
      id: id,
      like: 0,
    };
    this.accountService.addFavourits(data).subscribe((res) => {
      this.getFavProducts();
    });
  }
}
