import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchComponent } from './components/search/search.component';
import { DesignerComponent } from './components/designer/designer.component';
import { CustomerServiceComponent } from './components/customer-service/customer-service.component';
import { ContainerComponent } from './layout/container/container.component';
const routes: Routes = [

    {
        path: '',
        component: ContainerComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            {
                path: 'home',
                loadChildren: () =>
                    import('./components/homepage/homepage.module').then(
                        (m) => m.HomepageModule
                    ),
            },
            {
                path: 'about',
                loadChildren: () =>
                    import('./components/about/about.module').then((m) => m.AboutModule),
            },
            {
                path: 'account',
                loadChildren: () =>
                    import('./components/account/account.module').then(
                        (m) => m.AccountModule
                    ),
            },
            {
                path: 'cart',
                loadChildren: () =>
                    import('./components/cart/cart.module').then((m) => m.CartModule),
            },
            {
                path: 'survey',
                loadChildren: () =>
                    import('./components/survey/survey.module').then((m) => m.SurveyModule),
            },
            {
                path: 'products',
                loadChildren: () =>
                    import('./components/all-products/all-products.module').then(
                        (m) => m.AllProductsModule
                    ),
            },
            {
                path: 'register',
                loadChildren: () =>
                    import('./components/register/register.module').then(
                        (m) => m.RegisterModule
                    ),
            },
            { path: 'brand/:id', component: DesignerComponent },
            // { path: 'survey', component: SurveyComponent },
            { path: 'customer-service/:tab', component: CustomerServiceComponent },
            { path: 'search/:key', component: SearchComponent },
        ]
    }

];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
