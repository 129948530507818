import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageRoutingModule } from './homepage-routing.module';
import { HomepageComponent } from './homepage.component';
import { HomepageServicesComponent } from './homepage-services/homepage-services.component';
import { HomepageClientsComponent } from './homepage-clients/homepage-clients.component';
import { HomepageSliderComponent } from './homepage-slider/homepage-slider.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    HomepageComponent,
    HomepageSliderComponent,
    HomepageClientsComponent,
    HomepageServicesComponent,
  ],
  imports: [
    CommonModule,
    HomepageRoutingModule,
    FormsModule,
    TranslateModule.forChild(),
    NgbModule,
  ],
  exports: [HomepageClientsComponent],
})
export class HomepageModule {}
