import { SnackbarModule } from 'ngx-snackbar';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';

import { WishlistComponent } from './wishlist/wishlist.component';
import { AccountOverviewComponent } from './account-overview/account-overview.component';
import { UserMessagesComponent } from './user-messages/user-messages.component';
import { FavoriteListComponent } from './favorite-list/favorite-list.component';
import { AccountOrdersComponent } from './account-orders/account-orders.component';
import { AccountComponent } from './account/account.component';
import { DesignersFavoriteListComponent } from './designers-favorite-list/designers-favorite-list.component';
import { AccountMenuComponent } from './account-menu/account-menu.component';

@NgModule({
  declarations: [
    AccountComponent,
    AccountOrdersComponent,
    AccountMenuComponent,
    FavoriteListComponent,
    UserMessagesComponent,
    AccountOverviewComponent,
    WishlistComponent,
    DesignersFavoriteListComponent,
  ],
  imports: [CommonModule, AccountRoutingModule, FormsModule,TranslateModule, SnackbarModule],
})
export class AccountModule {}
