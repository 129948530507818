import { HomeService } from './../../services/home/home.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  socialData = {} as any;
  constructor(private homeService: HomeService) {}

  ngOnInit() {
    this.getFooterData()
  }
  getFooterData() {
    this.homeService.getFooterData().subscribe((res: any) => {
      this.socialData = res.social;
    });
  }
}
