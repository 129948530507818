<div class="container">
    <div style=" text-align: center; padding: 50px 0;">
        <h4>
           {{'homepage.search'|translate}}
        </h4>
    </div>
    <div class="row">
        <div class="col-md-3" style="height: 535px; display: contents;" *ngFor="let item of products">
            <div class="card card-inverse card-primary border-none ">
                <a [routerLink]="['/products/product', item.id]">
                    <div
                        style="background-position: center; width: 300px; overflow: hidden; height: 400px; background-size: cover; background-image: url({{item.images[0]}})">
                    </div>
                </a>
                <blockquote class="card-blockquote pt-3 pb-3">
                    <div class="product-card-title">
                        <a [routerLink]="['/products/product', item.id]">
                            <p class="p-text-ps">
                                {{item.name}}
                            </p>
                        </a>
                    </div>
                </blockquote>
            </div>
        </div>
        <!-- <div class="col-md-3" style="height: 535px; display: contents;" *ngFor="let designer of this.designer">
            <div class="card card-inverse card-primary border-none ">
                <a href="#/brand/{{designer.ID}}">
                    <div
                        style="background-position: center; width: 300px; overflow: hidden; height: 400px; background-size: cover; background-image: url({{this.BASEURL}}/uploads/{{designer.ImgURL}})">
                    </div>
                </a>
                <blockquote class="card-blockquote pt-3 pb-3">
                    <div class="product-card-title">
                        <a href="#/brand/{{designer.ID}}">
                            <p class="p-text-ps">
                                {{designer.Title}}
                            </p>
                        </a>
                    </div>
                </blockquote>
            </div>
        </div> -->
    </div>
</div>
