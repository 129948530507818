<div class="survey">
    <div class="survey-form">
        <div class="container">
            <h2 class="h2 shopping-bag-title text-uppercase"> {{'survey.question_three'|translate}} </h2>
            <div class="shopping-bag-body">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div class="left-side">
                            <form>
                              <div class="form-group">
                                <label for="exampleFormControlInput1">Email address</label>
                                <input type="email" name="email" [(ngModel)]="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">
                              </div>
                              <div class="price-actions float-left">
                                <button form="ngForm" class="btn btn-brand btn-icon-sm" color="primary"
                                class="btn btn-dark text-uppercase rounded-0" type="submit"
                                (click)="onSubmit()">{{'survey.finish'|translate}} </button>
                              </div>
                            </form>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</div>
