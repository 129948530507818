<div class="container" style="margin-top: 50px; margin-bottom: 50px">
  <div class="row">
    <div class="offset-md-6"></div>
    <div class="col-md-6">
      <img [src]="imgBase+privacy.image" style="width: 50%; height: 100%;" *ngIf="privacy.image != 'no'" />
    </div>
    <div class="col-md-12" style="
        border-bottom: 1px solid;
        border-top: solid 1px;
        padding: 40px 10px;
        max-width: 850px;
        margin: auto auto;
      " [innerHTML]="privacy.content"></div>
  </div>
</div>
