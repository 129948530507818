import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit {
  user = {} as any;
  firstName: string;
  lastName: string;
  fullName: string;
  firstCharOne: string;
  firstCharTwo: string;
  constructor(
    private router: Router,
    public translate: TranslateService,
  ) {}
  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.firstName = this.user['full_name'];
    this.lastName = this.user['last_name'];
    this.fullName = this.firstName + ' ' + this.lastName;
    this.firstCharOne = this.firstName.charAt(0);
    this.firstCharTwo = this.lastName.charAt(0);
  }
  logout() {
    sessionStorage.removeItem('logged');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('userId');
    this.router.navigateByUrl('/');
    window.location.reload();
  }
}
