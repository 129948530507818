import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { HomeService } from 'src/app/services/home/home.service';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @ViewChildren('dropdownmenu') dropdownmenu: QueryList<ElementRef>;
  isCollapsed: boolean = true;
  menuApper = false;
  brands: any[] = [];
  localBrands: any[] = [];
  mainMenu: any[] = [];
  scrolled = false;
  url = '/';
  constructor(
    private homeService: HomeService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {
    this.routerChange();
    this.url = this.route.snapshot['_routerState'].url;
    this.changeScroll();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.url = val.url;
        this.changeScroll();
      }
    });
  }
  routerChange() {}
  ngOnInit(): void {
    this.routerChange();
    this.getMenu();
  }
  changeScroll() {
    if (this.url == '/' || this.url == '/home') {
      if (window.scrollY > 20) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    } else {
      this.scrolled = true;
    }
  }
  @HostListener('window:scroll') onScroll(event) {
    this.changeScroll();
  }
  @HostListener('mouseover') onMouseOver(event) {
    if (this.url == '/' || this.url == '/home') {
      this.scrolled = true;
    } else {
      this.scrolled = true;
    }
    this.dropdownmenu.toArray().forEach((element) => {
      this.renderer.removeStyle(element.nativeElement, 'display');
    });
  }
  @HostListener('mouseout') onMouseOut() {
    if (this.url == '/' || this.url == '/home') {
      this.scrolled = false;
    } else {
      this.scrolled = true;
    }
    this.dropdownmenu.toArray().forEach((element) => {
      this.renderer.removeStyle(element.nativeElement, 'display');
    });
  }
  getMenu() {
    this.homeService.menuData().subscribe((res) => {
      res.menu.forEach((first) => {
        first['arrange'] = 1;
        let item = first;
        item.elements = [];
        first.items.forEach((second) => {
          second['arrange'] = 2;
          item.elements = [...item.elements, second];
          second.items.forEach((third) => {
            third['arrange'] = 3;
            item.elements = [...item.elements, third];
            third.items.forEach((fourth) => {
              fourth['arrange'] = 4;
              item.elements = [...item.elements, fourth];
            });
          });
        });
        delete item['items'];
        let ind = 0;
        item['categories'] = [];
        item.elements.forEach((element) => {
          delete element['items'];
          if (!item['categories'][ind]) {
            item['categories'][ind] = [];
          }
          item['categories'][ind] = [...item['categories'][ind], element];
          if (item['categories'][ind].length % 13 == 0) {
            ind++;
          }
        });
        this.mainMenu = [...this.mainMenu, item];
      });
      this.brands = res.designers;
      this.localBrands = res.local_brands;
      this.cdr.markForCheck();
    });
  }
  routerCategory(id) {
    this.router.navigateByUrl('/products/category/' + id).then(() => {
      this.HideDropdownMenu();
    });
  }
  routerBrand(id) {
    this.router.navigateByUrl('/brand/' + id).then(() => {
      this.HideDropdownMenu();
    });
  }
  HideDropdownMenu() {
    this.dropdownmenu.toArray().forEach((element) => {
      this.renderer.setStyle(element.nativeElement, 'display', 'none');
    });
    this.isCollapsed = true;
  }
  collapseNav(event){
   this.isCollapsed = event;
  }
}
