<!-- Account Menu -->

<!-- [Start account messages] -->
<div class="account-messages">
  <div class="container min-vh-100">
      <div class="all-messages">
          <div class="no-info">
            {{'account.there_no_messages' | translate}}
          </div>
      </div>
  </div>
</div>
<!-- [End account messages] -->
