import { AuthService } from './../../../services/auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-designer-confirmation',
  templateUrl: './designer-confirmation.component.html',
  styleUrls: ['./designer-confirmation.component.scss'],
})
export class DesignerConfirmationComponent implements OnInit {
  @ViewChild('designerConfirmationForm') designerConfirmationForm: NgForm;
  constructor(public modal: NgbActiveModal, private authService: AuthService) {}

  ngOnInit(): void {}
  designerConfirmation() {

  }

}
