<div class="cart">
    <div class="shopping-bag">
        <div class="container">
            <h2 class="h2 shopping-bag-title text-uppercase"> {{'cart.Shopping_bag'|translate}} </h2>
            <!-- <p class="shopping-bag-brief">
                You are currently in the United Arab Emirates store. Shipping to a different location?
            </p> -->
            <div class="shopping-bag-body">
                <div class="row">
                    <div class="col-12 col-lg-9">
                        <div class="left-side">
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                        <tr class="text-center">
                                            <td>
                                                {{'cart.Item'|translate}}
                                            </td>
                                            <td>
                                                {{'cart.Description'|translate}}
                                            </td>
                                            <td>
                                                {{'cart.Price'|translate}}   </td>
                                            <td>
                                                {{'cart.Quantity'|translate}}   </td>
                                            <td>
                                                {{'cart.Sub-total'|translate}}  </td>
                                        </tr>
                                        <tr *ngFor="let item of data; let i = index">
                                            <td>
                                                <img src="{{imgBase+item.Product.images[0]}}" alt=".." title="...">
                                            </td>
                                            <td class="description">
                                                <p class="item-info">
                                                    {{item.Product.name}}
                                                </p>
                                                <div class="color">
                                                    <span>
                                                        {{'products.Color'|translate}} :
                                                    </span>
                                                    <span>{{item.SelctedSubProduct.color.color}} </span>
                                                </div>
                                                <div class="color">
                                                    <span>
                                                        {{'products.Size'|translate}}: </span>
                                                    <span>{{item.SelctedSubProduct.size.size}} </span>
                                                </div>
                                                <div class="size" *ngIf="item.share == '1'">
                                                    <span>{{'products.Watch_at_home'|translate}} </span>
                                                </div>
                                                <div class="actions">
                                                    <a (click)="removeItem(item.id)"
                                                        style="cursor: -webkit-grab; cursor: grab;" class="remove"
                                                        title="Remove item">
                                                        {{'cart.Remove_item'|translate}}
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <a href="#" class="price">{{item.SelctedSubProduct.currency_id}}
                                                        <span id="price{{item.id}}" *ngIf="item.share == '1'" >
                                                            {{item.Product.show_at_home_price}}
                                                        </span>
                                                        <span id="price{{item.id}}" *ngIf="item.share == '0'" >
                                                            {{item.SelctedSubProduct.price}}
                                                        </span>
                                                </a>
                                            </td>
                                            <td>
                                                <select name="q" class="form-control" [(ngModel)]="item['quantity']"
                                                    (change)="updatetotal(item)" id="quan194">
                                                    <option *ngFor="let item of item.loopedArray" [value]="item">
                                                        {{item}}
                                                    </option>
                                                </select>

                                            </td>
                                            <td>
                                                <input type="hidden" id="0" value="14000">
                                                <a class="sub-total">{{item.SelctedSubProduct.currency_id}}
                                                        <span id="t194" *ngIf="item.share == '1'" >
                                                            {{item.Product.show_at_home_price}}
                                                        </span>
                                                        <span id="t194" *ngIf="item.share == '0'" >
                                                            {{item.price_id}}
                                                        </span>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="total-price">
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-6 col-md-4">
                                        <div class="titles">
                                            <p class="sub-total">
                                                {{'cart.Sub-total'|translate}} </p>
                                            <p class="estimated-total"> {{'cart.Estimated_Total'|translate}}  </p>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-4 text-right">
                                        <div class="prices">
                                            <p class="sub-total">SAR  <span id="sub">{{total}}</span> </p>
                                            <p class="estimated-total">SAR <span id="sub2">{{total}}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="price-actions float-right">
                                <a routerLink="/" class="text-uppercase" title="Return to store">
                                    {{'cart.RETURN_TO_STORE'|translate}}  </a>
                                <button form="ngForm" class="btn btn-brand btn-icon-sm" color="primary"
                                    class="btn btn-dark text-uppercase rounded-0" type="submit"
                                    (click)="onSubmit()">{{'cart.next_step'|translate}} </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <app-cart-aside></app-cart-aside>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
