import { AccountService } from './../../../services/account/account.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from './../../../services/products/products.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  catData = {} as any;

  imgBase = environment.imgURL;
  products: Array<any> = [];
  productsArr: Array<any> = [];
  logged = sessionStorage.getItem('logged');
  proudctId: number;
  constructor(
    private route: ActivatedRoute,
    private productsService: ProductsService,
    public translate: TranslateService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.proudctId = +params.get('id')!;
      this.getAllProductsOfCategories();
    });
  }
  getAllProductsOfCategories() {
    this.spinner.show('sp');
    this.productsService
      .getCategoryProduct(this.proudctId)
      .subscribe((res: any) => {
        this.spinner.hide('sp');
        this.catData = res.category_data;
        this.products = res.category_products;
        if (this.logged != '1') {
          this.products.forEach((pro) => {
            this.accountService.favouritProducts.forEach((fav) => {
              if (pro.id == fav.id) {
                pro.isFavourite = fav.isFavourite;
              }
            });
          });
        }
        this.productsArr = this.products;
        this.cdr.detectChanges();
      });
  }
  addToFavourit(product) {
    if (this.logged == '1') {
      const data = {
        id: product.id,
        like: 1,
      };
      this.accountService.addFavourits(data).subscribe((res) => {
        this.getAllProductsOfCategories();
        this.accountService.favDetails();
      });
    } else {
      this.products.forEach((p) => {
        if (p.id == product.id) {
          p.isFavourite = true;
        }
      });
      this.productsArr = this.products;
      product.isFavourite = true;
      this.accountService.favouritProducts.push(product);
      localStorage.setItem(
        'favouritProducts',
        JSON.stringify(this.accountService.favouritProducts)
      );
    }
  }
  removeFromFavourit(product) {
    if (this.logged == '1') {
      const data = {
        id: product.id,
        like: 0,
      };
      this.accountService.addFavourits(data).subscribe((res) => {
        this.getAllProductsOfCategories();
        this.accountService.favDetails();
      });
    } else {
      this.products.forEach((p) => {
        if (p.id == product.id) {
          p.isFavourite = false;
        }
      });
      product.isFavourite = false;
      this.productsArr = this.products;
      const index = this.accountService.favouritProducts.findIndex(
        (p) => p.id == product.id
      );
      this.accountService.favouritProducts.splice(index, 1);
      localStorage.setItem(
        'favouritProducts',
        JSON.stringify(this.accountService.favouritProducts)
      );
    }
  }
}
