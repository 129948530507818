import { SnackbarService } from 'ngx-snackbar';
import { NgForm } from '@angular/forms';
import { HomeService } from 'src/app/services/home/home.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-customer-service',
  templateUrl: './customer-service.component.html',
  styleUrls: ['./customer-service.component.scss'],
})
export class CustomerServiceComponent implements OnInit {
  customerServices = {} as any;
  active = 'Contact';
  loading: boolean;
  logged = sessionStorage.getItem('logged');
  userData = JSON.parse(sessionStorage.getItem('user'));
  @ViewChild('contactUsForm') contactUsForm: NgForm;
  issueValue: string = '';
  constructor(
    private homeService: HomeService,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService
  ) {}
  ngOnInit() {
    this.getCustomerService();
    this.route.paramMap.subscribe((params) => {
      this.active = params.get('tab');
    });
    console.log(this.issueValue);
  }
  getCustomerService() {
    this.homeService.getFooterData().subscribe((res: any) => {
      this.customerServices = res.Customer_services;
    });
  }
  contactSubmit() {
    this.loading = true;
    if (this.logged == '1') {
      this.contactUsForm.value.email = this.userData.email;
    }
    this.homeService
      .contactUs(this.contactUsForm.value)
      .subscribe((res: any) => {
        console.log(res);
        this.loading = false;
        this.contactUsForm.reset();
        this.snackbarService.add({
          msg: res.message,
          timeout: 4000,
          background: '#eee',
          color: '#333',
          action: {
            text: 'x',
            color: 'red',
          },
        });
      });
  }
}
