<nav class="navbar navbar-expand-lg navbar-light main-nav" [ngClass]="scrolled ? 'defult-style' : 'custom-style'">
    <div class="nav-logo">
        <a class="navbar-brand" href="#">
            <img *ngIf="scrolled == false" src="assets/img/logo.png" alt="">
            <img *ngIf="scrolled == true" src="assets/img/logo-b.png" alt="">
        </a>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav">
            <li class="nav-item dropdown" *ngFor="let menu of mainMenu">
                <a class="nav-link dropdown-toggle" id="fDropdown" role="button" aria-haspopup="true"
                    aria-expanded="false">
                    {{menu.name}}
                </a>
                <div class="dropdown-menu" aria-labelledby="fDropdown" #dropdownmenu>
                    <div class="navdown-div">
                        <div class="row">
                            <div class="col-md-3">
                                <a class="head cp p-0 mb-1" style="font-size:16px" (click)="routerCategory(menu.id)">
                                    {{'navbar.all_products'|translate}}
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-6" *ngFor="let elements of menu.categories">
                                <!-- arrange 2  sub -->
                                <div *ngFor="let item of elements">
                                    <h6 class="head cp" *ngIf="item.arrange == 2" (click)="routerCategory(item.id)">
                                        {{item.name}}
                                    </h6>
                                    <!-- arrange 3 sumenu -->
                                    <a class="cat cp" *ngIf="item.arrange == 3" (click)="routerCategory(item.id)">
                                        {{item.name}}
                                    </a>
                                    <!-- arrange 4 item -->
                                    <a class="sub-a cp" *ngIf="item.arrange == 4" (click)="routerCategory(item.id)">
                                        {{item.name}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="brandDropdown" role="button" aria-haspopup="true"
                    aria-expanded="false">
                    {{'navbar.brands' | translate}}
                </a>
                <div class="dropdown-menu" aria-labelledby="brandDropdown" #dropdownmenu>
                    <div class="navdown-div">
                        <div *ngFor="let brand of brands">
                            <h6 class="head">{{brand.name}}</h6>
                            <div *ngFor="let designer of brand.designers">
                                <a class="sub-a" (click)="routerBrand(designer.id)">
                                    {{designer.name}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="localbrandDropdown" role="button" aria-haspopup="true"
                    aria-expanded="false">
                    {{'navbar.localBrnds' | translate}}
                </a>
                <div class="dropdown-menu" aria-labelledby="localbrandDropdown" #dropdownmenu>
                    <div class="navdown-div">
                        <div *ngFor="let localBrand of localBrands">
                            <h6 class="head">
                                {{localBrand.name}}
                            </h6>
                            <div *ngFor="let item of localBrand.local_items">
                                <a class="sub-a" [href]="item.url" target="_blank">
                                    {{item.name}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <app-sub-nav [scrolled]="scrolled" (isCollapsed)="collapseNav($event)"></app-sub-nav>
    </div>
</nav>