import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoriesComponent } from './categories/categories.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { ProductsComponent } from './products/products.component';
import { UpdateProductComponent } from './update_product/update_product.component';

const routes: Routes = [
  { path: '', component: ProductsComponent },
  { path: 'product/update/:id', component: UpdateProductComponent },
  { path: 'product/:id', component: ProductViewComponent },
  { path: 'category/:id', component: CategoriesComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AllProductsRoutingModule {}
