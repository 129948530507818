import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'how.component',
  templateUrl: './how.component.html',
  styleUrls: ['./how.component.scss'],
})
export class HowComponent implements OnInit {
  loading = false;
  model: any = {};
  loginError;
  constructor(
    private router: Router
  ) {}
  ngOnInit(): void {
    if (sessionStorage.getItem('logged') == '1') {
      let user = sessionStorage.getItem('userId');
      console.log(user);

    }
  }
  onSubmit() {
    this.router.navigateByUrl('survey/question2');
  }
}
