import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HomeService } from 'src/app/services/home/home.service';
import {
  ModalDismissReasons,
  NgbCarousel,
  NgbModal,
  NgbSlideEvent,
  NgbSlideEventSource,
} from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-homepage-slider',
  templateUrl: './homepage-slider.component.html',
  styleUrls: ['./homepage-slider.component.scss'],
})
export class HomepageSliderComponent implements OnInit {
  imgBase = environment.imgURL;
  images = [];
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = true;
  pauseOnHover = true;
  pauseOnFocus = true;
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  closeResult: string;
  aboutData = {} as any;
  constructor(
    private homeService: HomeService,
    public modalService: NgbModal
  ) {}
  ngOnInit() {
    this.getSliderData();
  }
  getSliderData() {
    this.homeService.getHomeData().subscribe((res: any) => {
      this.images = res.home_slider;
      console.log(this.images);
      if (window.innerWidth <= 768) {
        this.images = res.mobile_slider;
        console.log(this.images);
      }
      this.aboutData = res.about;
      console.log(this.aboutData)
    });
  }
  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }
  onSlide(slideEvent: NgbSlideEvent) {
    if (
      this.unpauseOnArrow &&
      slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT ||
        slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)
    ) {
      this.togglePaused();
    }
    if (
      this.pauseOnIndicator &&
      !slideEvent.paused &&
      slideEvent.source === NgbSlideEventSource.INDICATOR
    ) {
      this.togglePaused();
    }
  }
  open(content) {
    this.modalService.open(content, { size: 'lg', centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
