<div class="container">
  <div class="row services2">
    <div class="col-md-12 col-xs-12 text-center">
      <h4 class="text-center" [innerHTML]="serviceData.service_title">
      </h4>
    </div>
    <div class="col-md-4 col-sm-12 margin-top-2rem text-center">
      <span class="d-inline-block col-md-4">
        <img src="assets/img/product.png" class="img-fluid" />
      </span>
      <div class="f-22 d-inline-block text-right line-height-30 col-md-8" [innerHTML]="serviceData.service1"></div>
    </div>
    <div class="col-md-4 col-sm-12 margin-top-2rem text-center">
      <span class="d-inline-block col-md-4" style="position: relative; bottom: 32%;">
        <img src="assets/img/return.png" class="img-fluid" />
      </span>
      <div class="f-22 d-inline-block text-right line-height-30 col-md-8" [innerHTML]="serviceData.service2"></div>
    </div>
    <div class="col-md-4 col-sm-12 margin-top-2rem text-center">
      <span class="d-inline-block col-md-4">
        <img src="assets/img/delivery-truck.png" class="img-fluid" />
      </span>
      <div class="f-22 d-inline-block text-right line-height-30 col-md-8" [innerHTML]="serviceData.service3"></div>
    </div>
  </div>
</div>