import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-parteners',
  templateUrl: './parteners.component.html',
  styleUrls: ['./parteners.component.scss'],
})
export class PartenersComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
