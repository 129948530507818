<div class="about-menu">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" routerLink="/about/company" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
      true}">{{ "about_us_page.about_title" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/about/parteners" routerLinkActive="active">{{ "about_us_page.parteners_title" |
        translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/about/policy-and-privacy" routerLinkActive="active">
        {{ "about_us_page.privacy_title" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/about/legal" routerLinkActive="active">
        {{ "about_us_page.legal_title" | translate }}
      </a>
    </li>
  </ul>
</div>
<div class="tab-content">
  <router-outlet></router-outlet>
</div>