import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutCompanyComponent } from './about-company/about-company.component';
import { LegalComponent } from './legal/legal.component';
import { PartenersComponent } from './parteners/parteners.component';
import { PolicyAndPrivacyComponent } from './policy-and-privacy/policy-and-privacy.component';
import { AboutCompanyMenuComponent } from './about-company-menu/about-company-menu.component';

const routes: Routes = [
  {
    path: '',
    component: AboutCompanyMenuComponent,
    children: [
      { path: '', redirectTo: 'company', pathMatch: 'full' },
      { path: 'company', component: AboutCompanyComponent },
      { path: 'policy-and-privacy', component: PolicyAndPrivacyComponent },
      { path: 'parteners', component: PartenersComponent },
      { path: 'legal', component: LegalComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AboutRoutingModule {}
