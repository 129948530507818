import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HowComponent } from './how/how.component';
import { WhatComponent } from './what/what.component';
import { WhereComponent } from './where/where.component';
// import { Question3Component } from 'question3/question3.component';
const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: HowComponent },
      { path: 'question2', component: WhatComponent },
      { path: 'question3', component: WhereComponent },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SurveyRoutingModule {}
