import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomerServiceComponent } from './components/customer-service/customer-service.component';
import { DesignerComponent } from './components/designer/designer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorInterceptor } from './services/intercept/interceptor.interceptor';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from './components/search/search.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SplashScreenComponent } from './layout/splash-screen/splash-screen.component';
import { LightboxModule } from 'ngx-lightbox';
import { SnackbarModule } from 'ngx-snackbar';
import { NgxSpinnerModule } from "ngx-spinner";
import { ContainerComponent } from './layout/container/container.component';
import { NavComponent } from './layout/nav/nav.component';
import { SubNavComponent } from './layout/nav/sub-nav/sub-nav.component';
// AoT requires an exported function for factories
@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        SplashScreenComponent,
        CustomerServiceComponent,
        DesignerComponent,
        SearchComponent,
        ContainerComponent,
        NavComponent,
        SubNavComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot(),
        LightboxModule,
        NgxSpinnerModule,
        SnackbarModule.forRoot(),
        NgbModule
    ],
    exports: [TranslateModule],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
