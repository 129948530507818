<video style="width: 100%;" autoplay="" muted="" loop="" id="myVideo" *ngIf="aboutData.video != 'no'">
  <source [src]="imgBase+aboutData.video" type="video/mp4">
</video>
<div class="container">
  <div class="row">
    <div class="col-md-12 text-center"
      style="border-bottom: 1px solid; border-top: solid 1px; padding: 40px 10px; max-width: 850px; margin: auto auto;"
      [innerHTML]="aboutData.content"> </div>
  </div>
</div>
