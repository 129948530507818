<form #forgetPass="ngForm" (ngSubmit)="forgetPassword()">
    <div class="modal-body text-center">
        <h6>
            Email
        </h6>
        <input type="text" class="form-control" name="email" ngModel>
    </div>
    <div class="modal-footer border-none justify-content-center">
        <button type="submit" class="btn btn-dark">
            Send Password
        </button>
        <button type="button" (click)="modal.dismiss()" class="btn btn-dark">
            Cancle
        </button>
    </div>
</form>