import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-messages',
  templateUrl: './user-messages.component.html',
  styleUrls: ['./user-messages.component.scss']
})
export class UserMessagesComponent implements OnInit {

  activeClassMenu = 'messages'

  constructor(
    private router: Router,

  ) {
    if(sessionStorage.getItem('logged') != '1' ) {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }



}
