<div class="account-categories">
  <div class="container">
    <div class="row">
      <!-- Start My Account Box -->
      <div class="col-12 col-md-6">
        <div class="category">
          <h4 class="h4 category-title text-uppercase"> {{'account.my_account' | translate}} </h4>
          <div class="email text-uppercase">
            <span class="email-title"> {{'account.email' | translate}}</span>
            <span class="email-body"> {{userData.email}} </span>
          </div>
          <div class="contact-preferences">
            <h6 class="h6 contact-preferences-title text-uppercase"> {{'account.CONTACT_PREFERENCES' | translate}} </h6>
            <div class="contact-preferences-body">
              <div class="row">
                <div class="col-4 col-md-4 text-center" style="border-right: solid 1px #cdcdcd;">
                  <div class="content">
                    <div class="icon">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                    </div>
                    <div class="box-title"> {{'account.Phone' | translate}} </div>
                    <div class="remove-icon">
                      <a href="#">
                        <i class="fa fa-close" aria-hidden="true" *ngIf="userData.phone == ''"></i>
                        <i class="fa fa-check" style="color: green;" aria-hidden="true"
                          *ngIf="userData.phone != ''"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-4 col-md-4 text-center" style="border-right: solid 1px #cdcdcd;">
                  <div class="content">
                    <div class="icon">
                      <i class="fa fa-comments" aria-hidden="true"></i>
                    </div>
                    <div class="box-title"> {{'account.Address' | translate}} </div>
                    <div class="remove-icon">
                      <a href="#">
                        <i class="fa fa-close" aria-hidden="true" *ngIf="userData.address == ''"></i>
                        <i class="fa fa-check" style="color: green;" aria-hidden="true"
                          *ngIf="userData.address != ''"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-4 col-md-4 text-center">
                  <div class="content">
                    <div class="icon">
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                    </div>
                    <div class="box-title"> {{'account.Mails' | translate}} </div>
                    <div class="remove-icon">
                      <div class="remove-icon">
                        <a href="#">
                          <i class="fa fa-close" aria-hidden="true" *ngIf="userData.email == ''"></i>
                          <i class="fa fa-check" style="color: green;" aria-hidden="true"
                            *ngIf="userData.email != ''"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="category-btn">
            <a routerLink="/account/profile-info" class="btn btn-dark text-center text-uppercase">
              {{'account.Edit_My_Profile' | translate}} </a>
          </div>
        </div>
      </div>
      <!-- End My Account Box -->
      <!-- Start My Orders Box -->
      <div class="col-12 col-md-6">
        <div class="category">
          <h4 class="h4 category-title text-uppercase"> {{'account.Current_Orders' | translate}} </h4>
          <div style="min-height: 225px;">
            <div class="no-favorite-designers no-details" style=" text-align: center;" *ngIf="myCart.length == 0">
              {{'account.No_Orders' | translate}} </div>
            <div class="account-overview-favorite-item order-item-in-overview"
              *ngFor="let order of myCart| slice:0:1; let i = index">
              <div class="row" *ngFor="let item of order.items">
                <div class="col-6 col-md-4">
                  <div class="preview-order">
                    <a [routerLink]="['/products/product', item.Product['id']]" class="fav">
                      <img [src]="imgBase+item.Product.images[0]" style="object-fit: cover; width: 100%; height: 15em;"
                        alt="...">
                      <div class="wishitem-description">
                        {{item.Product['name']}}
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-6 col-md-8">
                  <div class="order-info">
                    <h6>#B{{order.id}}</h6>
                    <p>
                      {{'account.not_finalized_yet' | translate}}
                    </p>
                    <p>
                      {{'products.Color' | translate}}
                      {{item.Product.sub_products[0]['color']['color']}}<br>
                      {{'products.Size' | translate}} {{item.size_id}}<br>
                      {{'products.Quantity' | translate}} {{item.quantity}}<br>
                      {{'products.Price' | translate}} {{item.price_id}}<br>
                      <br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="category-btn">
            <a routerLink="/account/orders" class="btn btn-dark text-center text-uppercase">
              {{'account.My_Orders' | translate}} </a>
          </div>
        </div>
      </div>
      <!-- End My Orders Box -->
      <!-- Start My Wishlist Box -->
      <div class="col-12 col-md-6">
        <div class="category">
          <h4 class="h4 category-title text-uppercase"> {{'account.my_favorite' | translate}} <sup></sup></h4>
          <div style="min-height: 206px;" class="row custom-box-for-designer-and-product">
            <div class="col-md-6 account-overview-favorite-item"
              *ngFor="let item of myFavoriteList| slice:0:2; let i = index">
              <div class="card card-inverse card-primary border-none p-2">
                <a [routerLink]="['/products/product', item.id]">
                  <img [src]="imgBase+item.images[0]" alt="">
                </a>
                <blockquote class="card-blockquote p-3">
                  <div class="product-card-title">
                    <a [routerLink]="['/products/product', item.id]">
                      <p class="p-text-ps">{{item.name}} </p>
                    </a>
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
          <div class="category-btn">
            <a routerLink="/account/favorite" class="btn btn-dark text-center text-uppercase">
              {{'account.edit_my_favorite' | translate}} </a>
          </div>
        </div>
      </div>
      <!-- End My Wishlist Box -->
      <!-- Start My Favorite Designer Box  -->
      <div class="col-12 col-md-6">
        <div class="category">
          <h4 class="h4 category-title text-uppercase"> {{'account.my_favorite_designers' | translate}}</h4>
          <div class="row my-favorite-designers text-center custom-box-for-designer-and-product" style="min-height: 206px;">
            <div class="col-md-6 account-overview-favorite-item"
              *ngFor="let item of brandsFavoriteList | slice:0:2; let i = index">
              <div class="card card-inverse card-primary border-none p-2">
                <a [routerLink]="['/brand', item.id]" *ngIf="item.image">
                  <img [src]="imgBase+item.image" alt="" style="object-fit: contain;">
                </a>
                <blockquote class="card-blockquote p-3">
                  <div class="product-card-title">
                    <a [routerLink]="['/brand', item.id]">
                      <p style="font-size: 1.2em;font-weight: bold; letter-spacing: 2px;"> {{item.name}} </p>
                      <p class="small muted" style="font-weight: bold;letter-spacing: 1px;">{{item.category}}</p>
                    </a>
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
          <div class="category-btn">
            <a routerLink="/account/favorite-designers" class="btn btn-dark text-center text-uppercase"
              style="color: #FFF;">
              {{'account.View_all' | translate}} </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- [End account categories] -->
