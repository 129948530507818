<div class="products">
  <div class="container">
    <div class="row">
      <div class="col text-center mt-4 mb-4">
        <h5 class="">All products
        </h5>
      </div>
    </div>
    <section id="accordion-with-margin">
      <div class="row">
        <div class="col-sm-12">
          <div class="card collapse-icon accordion-icon-rotate">
            <div class="card-body">
              <div class="accordion" id="accordionExample">
                <div class="collapse-margin">
                  <div class="card-header" id="headingOne" data-toggle="collapse" role="button" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    <span class="lead collapse-title">
                      Sub Product One
                    </span>
                  </div>
                  <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                      <div class="form-group" style="margin: 10px 0px;">
                        <label for="size" class="col-md-4 control-label">Size
                        </label>
                        <div class="col-md-8">
                          <input type="text" name="size" value="1" class="form-control" id="size" placeholder="Enter Size" />
                        </div>
                      </div>
                      <div class="form-group" style="margin: 10px 0px;">
                        <div class="col-sm-offset-4 col-sm-8">
                          <button type="submit" class="btn btn-dark">Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="collapse-margin">
                  <div class="card-header" id="headingTwo" data-toggle="collapse" role="button" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <span class="lead collapse-title">
                      Sub Product Two
                    </span>
                  </div>
                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div class="card-body">
                      <div class="form-group" style="margin:  10px 0px;">
                        <label for="color" class="col-md-4 control-label">Color
                        </label>
                        <div class="col-md-8">
                          <input type="text"  name="color" value="" class="form-control" id="color" placeholder="Enter Color" />
                        </div>
                      </div>
                      <div class="form-group" style="margin:  10px 0px;">
                        <label for="color" class="col-md-4 control-label">اللون
                        </label>
                        <div class="col-md-8">
                          <input type="text"  name="color_ar" value="" class="form-control" id="color_ar" placeholder="أدخل اللون"/>
                        </div>
                      </div>
                      <div class="form-group" style="margin:  10px 0px;">
                        <label for="img_id" class="col-md-4 control-label">Image
                        </label>
                        <div class="col-md-8">
                          <input type="file" required name="img" required="required" onchange="readURL(this);" />
                          <img id="blah" src="#" alt="your image" style=" display: none" />
                        </div>
                      </div>
                      <div class="form-group" style="margin:  10px 0px;">
                        <div class="col-sm-offset-4 col-sm-8">
                          <button type="submit" class="btn btn-dark">Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="mt-5 mb-5">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputname">Name
          </label>
          <input type="text" name="name" value="{{ productData.name }}" class="form-control"  placeholder="Name">
        </div>
        <div class="form-group col-md-6">
          <label for="اسم الإدخال">الاسم
          </label>
          <input type="text" name="name_ar" value="{{ productData.name_ar }}" class="form-control"  placeholder="أدخل الاسم">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputdesigner">Designer
          </label>
          <select name="created_by" class="form-control">
            <option value="">select Designer</option>
            </select>
        </div>
        <div class="form-group col-md-6">
        <label for="category_id">Show At Home</label>
            <select name="show_at_home" class="form-control">
              <option disabled selected value>Nothing Seceleted
              </option>
                <option>Yes</option>
                <option> No </option>
            </select>
      </div>
    </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputcity">City
          </label>
          <input type="text" class="form-control"  placeholder="Enter City Name">
        </div>
        <div class="form-group col-md-6">
          <label for="inputState">State
          </label>
          <select id="inputState" class="form-control">
            <option value="Afghanistan">Afghanistan
            </option>
            <option value="Aland Islands">Aland Islands
            </option>
            <option value="Albania">Albania
            </option>
            <option value="Algeria">Algeria
            </option>
            <option value="American Samoa">American Samoa
            </option>
            <option value="Andorra">Andorra
            </option>
            <option value="Angola">Angola
            </option>
            <option value="Anguilla">Anguilla
            </option>
            <option value="Antarctica">Antarctica
            </option>
            <option value="Antigua and Barbuda">Antigua and Barbuda
            </option>
            <option value="Argentina">Argentina
            </option>
            <option value="Armenia">Armenia
            </option>
            <option value="Aruba">Aruba
            </option>
            <option value="Australia">Australia
            </option>
            <option value="Austria">Austria
            </option>
            <option value="Azerbaijan">Azerbaijan
            </option>
            <option value="Bahamas">Bahamas
            </option>
            <option value="Bahrain">Bahrain
            </option>
            <option value="Bangladesh">Bangladesh
            </option>
            <option value="Barbados">Barbados
            </option>
            <option value="Belarus">Belarus
            </option>
            <option value="Belgium">Belgium
            </option>
            <option value="Belize">Belize
            </option>
            <option value="Benin">Benin
            </option>
            <option value="Bermuda">Bermuda
            </option>
            <option value="Bhutan">Bhutan
            </option>
            <option value="Bolivia">Bolivia
            </option>
            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina
            </option>
            <option value="Botswana">Botswana
            </option>
            <option value="Bouvet Island">Bouvet Island
            </option>
            <option value="Brazil">Brazil
            </option>
            <option value="British Indian Ocean Territory">British Indian Ocean Territory
            </option>
            <option value="Brunei Darussalam">Brunei Darussalam
            </option>
            <option value="Bulgaria">Bulgaria
            </option>
            <option value="Burkina Faso">Burkina Faso
            </option>
            <option value="Burundi">Burundi
            </option>
            <option value="Cambodia">Cambodia
            </option>
            <option value="Cameroon">Cameroon
            </option>
            <option value="Canada">Canada
            </option>
            <option value="Cape Verde">Cape Verde
            </option>
            <option value="Cayman Islands">Cayman Islands
            </option>
            <option value="Central African Republic">Central African Republic
            </option>
            <option value="Chad">Chad
            </option>
            <option value="Chile">Chile
            </option>
            <option value="China">China
            </option>
            <option value="Christmas Island">Christmas Island
            </option>
            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands
            </option>
            <option value="Colombia">Colombia
            </option>
            <option value="Comoros">Comoros
            </option>
            <option value="Congo">Congo
            </option>
            <option value="Cook Islands">Cook Islands
            </option>
            <option value="Costa Rica">Costa Rica
            </option>
            <option value="Croatia">Croatia
            </option>
            <option value="Cuba">Cuba
            </option>
            <option value="Curaçao">Curaçao
            </option>
            <option value="Cyprus">Cyprus
            </option>
            <option value="Czech Republic">Czech Republic
            </option>
            <option value="Denmark">Denmark
            </option>
            <option value="Djibouti">Djibouti
            </option>
            <option value="Dominica">Dominica
            </option>
            <option value="Dominican Republic">Dominican Republic
            </option>
            <option value="Ecuador">Ecuador
            </option>
            <option value="Egypt">Egypt
            </option>
            <option value="El Salvador">El Salvador
            </option>
            <option value="Equatorial Guinea">Equatorial Guinea
            </option>
            <option value="Eritrea">Eritrea
            </option>
            <option value="Estonia">Estonia
            </option>
            <option value="Ethiopia">Ethiopia
            </option>
            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)
            </option>
            <option value="Faroe Islands">Faroe Islands
            </option>
            <option value="Fiji">Fiji
            </option>
            <option value="Finland">Finland
            </option>
            <option value="France">France
            </option>
            <option value="French Guiana">French Guiana
            </option>
            <option value="French Polynesia">French Polynesia
            </option>
            <option value="French Southern and Antarctic Lands">French Southern and Antarctic Lands
            </option>
            <option value="Gabon">Gabon
            </option>
            <option value="Gambia">Gambia
            </option>
            <option value="Georgia">Georgia
            </option>
            <option value="Germany">Germany
            </option>
            <option value="Ghana">Ghana
            </option>
            <option value="Gibraltar">Gibraltar
            </option>
            <option value="Greece">Greece
            </option>
            <option value="Greenland">Greenland
            </option>
            <option value="Grenada">Grenada
            </option>
            <option value="Guadeloupe">Guadeloupe
            </option>
            <option value="Guam">Guam
            </option>
            <option value="Guatemala">Guatemala
            </option>
            <option value="Guernsey">Guernsey
            </option>
            <option value="Guinea">Guinea
            </option>
            <option value="Guinea-Bissau">Guinea-Bissau
            </option>
            <option value="Guyana">Guyana
            </option>
            <option value="Haiti">Haiti
            </option>
            <option value="Heard and Mc Donald Islands">Heard and Mc Donald Islands
            </option>
            <option value="Honduras">Honduras
            </option>
            <option value="Hong Kong">Hong Kong
            </option>
            <option value="Hungary">Hungary
            </option>
            <option value="Iceland">Iceland
            </option>
            <option value="India">India
            </option>
            <option value="Indonesia">Indonesia
            </option>
            <option value="Iran">Iran
            </option>
            <option value="Iraq">Iraq
            </option>
            <option value="Ireland">Ireland
            </option>
            <option value="Isle of Man">Isle of Man
            </option>
            <option value="Israel">Israel
            </option>
            <option value="Italy">Italy
            </option>
            <option value="Ivory Coast">Ivory Coast
            </option>
            <option value="Jamaica">Jamaica
            </option>
            <option value="Japan">Japan
            </option>
            <option value="Jersey">Jersey
            </option>
            <option value="Jordan">Jordan
            </option>
            <option value="Kazakhstan">Kazakhstan
            </option>
            <option value="Kenya">Kenya
            </option>
            <option value="Kiribati">Kiribati
            </option>
            <option value="Korea(North Korea)">Korea(North Korea)
            </option>
            <option value="Korea(South Korea)">Korea(South Korea)
            </option>
            <option value="Kosovo">Kosovo
            </option>
            <option value="Kuwait">Kuwait
            </option>
            <option value="Kyrgyzstan">Kyrgyzstan
            </option>
            <option value="Lao PDR">Lao PDR
            </option>
            <option value="Latvia">Latvia
            </option>
            <option value="Lebanon">Lebanon
            </option>
            <option value="Lesotho">Lesotho
            </option>
            <option value="Liberia">Liberia
            </option>
            <option value="Libya">Libya
            </option>
            <option value="Liechtenstein">Liechtenstein
            </option>
            <option value="Lithuania">Lithuania
            </option>
            <option value="Luxembourg">Luxembourg
            </option>
            <option value="Macau">Macau
            </option>
            <option value="Macedonia">Macedonia
            </option>
            <option value="Madagascar">Madagascar
            </option>
            <option value="Malawi">Malawi
            </option>
            <option value="Malaysia">Malaysia
            </option>
            <option value="Maldives">Maldives
            </option>
            <option value="Mali">Mali
            </option>
            <option value="Malta">Malta
            </option>
            <option value="Marshall Islands">Marshall Islands
            </option>
            <option value="Martinique">Martinique
            </option>
            <option value="Mauritania">Mauritania
            </option>
            <option value="Mauritius">Mauritius
            </option>
            <option value="Mayotte">Mayotte
            </option>
            <option value="Mexico">Mexico
            </option>
            <option value="Micronesia">Micronesia
            </option>
            <option value="Moldova">Moldova
            </option>
            <option value="Monaco">Monaco
            </option>
            <option value="Mongolia">Mongolia
            </option>
            <option value="Montenegro">Montenegro
            </option>
            <option value="Montserrat">Montserrat
            </option>
            <option value="Morocco">Morocco
            </option>
            <option value="Mozambique">Mozambique
            </option>
            <option value="Myanmar">Myanmar
            </option>
            <option value="Namibia">Namibia
            </option>
            <option value="Nauru">Nauru
            </option>
            <option value="Nepal">Nepal
            </option>
            <option value="Netherlands">Netherlands
            </option>
            <option value="Netherlands Antilles">Netherlands Antilles
            </option>
            <option value="New Caledonia">New Caledonia
            </option>
            <option value="New Zealand">New Zealand
            </option>
            <option value="Nicaragua">Nicaragua
            </option>
            <option value="Niger">Niger
            </option>
            <option value="Nigeria">Nigeria
            </option>
            <option value="Niue">Niue
            </option>
            <option value="Norfolk Island">Norfolk Island
            </option>
            <option value="Northern Mariana Islands">Northern Mariana Islands
            </option>
            <option value="Norway">Norway
            </option>
            <option value="Oman">Oman
            </option>
            <option value="Pakistan">Pakistan
            </option>
            <option value="Palau">Palau
            </option>
            <option value="Palestine">Palestine
            </option>
            <option value="Panama">Panama
            </option>
            <option value="Papua New Guinea">Papua New Guinea
            </option>
            <option value="Paraguay">Paraguay
            </option>
            <option value="Peru">Peru
            </option>
            <option value="Philippines">Philippines
            </option>
            <option value="Pitcairn">Pitcairn
            </option>
            <option value="Poland">Poland
            </option>
            <option value="Portugal">Portugal
            </option>
            <option value="Puerto Rico">Puerto Rico
            </option>
            <option value="Qatar">Qatar
            </option>
            <option value="Reunion Island">Reunion Island
            </option>
            <option value="Romania">Romania
            </option>
            <option value="Russian">Russian
            </option>
            <option value="Rwanda">Rwanda
            </option>
            <option value="Saint Barthelemy">Saint Barthelemy
            </option>
            <option value="Saint Helena">Saint Helena
            </option>
            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis
            </option>
            <option value="Saint Martin (French part)">Saint Martin (French part)
            </option>
            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon
            </option>
            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines
            </option>
            <option value="Samoa">Samoa
            </option>
            <option value="San Marino">San Marino
            </option>
            <option value="Sao Tome and Principe">Sao Tome and Principe
            </option>
            <option selected value="Saudi Arabia">Saudi Arabia
            </option>
            <option value="Senegal">Senegal
            </option>
            <option value="Serbia">Serbia
            </option>
            <option value="Seychelles">Seychelles
            </option>
            <option value="Sierra Leone">Sierra Leone
            </option>
            <option value="Singapore">Singapore
            </option>
            <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)
            </option>
            <option value="Slovakia">Slovakia
            </option>
            <option value="Slovenia">Slovenia
            </option>
            <option value="Solomon Islands">Solomon Islands
            </option>
            <option value="Somalia">Somalia
            </option>
            <option value="South Africa">South Africa
            </option>
            <option value="South Georgia and the South Sandwich">South Georgia and the South Sandwich
            </option>
            <option value="South Sudan">South Sudan
            </option>
            <option value="Spain">Spain
            </option>
            <option value="Sri Lanka">Sri Lanka
            </option>
            <option value="Sudan">Sudan
            </option>
            <option value="Suriname">Suriname
            </option>
            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen
            </option>
            <option value="Swaziland">Swaziland
            </option>
            <option value="Sweden">Sweden
            </option>
            <option value="Switzerland">Switzerland
            </option>
            <option value="Syria">Syria
            </option>
            <option value="Taiwan">Taiwan
            </option>
            <option value="Tajikistan">Tajikistan
            </option>
            <option value="Tanzania">Tanzania
            </option>
            <option value="Thailand">Thailand
            </option>
            <option value="Timor-Leste">Timor-Leste
            </option>
            <option value="Togo">Togo
            </option>
            <option value="Tokelau">Tokelau
            </option>
            <option value="Tonga">Tonga
            </option>
            <option value="Trinidad and Tobago">Trinidad and Tobago
            </option>
            <option value="Tunisia">Tunisia
            </option>
            <option value="Turkey">Turkey
            </option>
            <option value="Turkmenistan">Turkmenistan
            </option>
            <option value="Turks and Caicos Islands">Turks and Caicos Islands
            </option>
            <option value="Tuvalu">Tuvalu
            </option>
            <option value="Uganda">Uganda
            </option>
            <option value="Ukraine">Ukraine
            </option>
            <option value="United Arab Emirates">United Arab Emirates
            </option>
            <option value="United Kingdom">United Kingdom
            </option>
            <option value="United States">United States
            </option>
            <option value="Uruguay">Uruguay
            </option>
            <option value="US Minor Outlying Islands">US Minor Outlying Islands
            </option>
            <option value="Uzbekistan">Uzbekistan
            </option>
            <option value="Vanuatu">Vanuatu
            </option>
            <option value="Vatican City">Vatican City
            </option>
            <option value="Venezuela">Venezuela
            </option>
            <option value="Vietnam">Vietnam
            </option>
            <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)
            </option>
            <option value="Wallis and Futuna Islands">Wallis and Futuna Islands
            </option>
            <option value="Western Sahara">Western Sahara
            </option>
            <option value="Yemen">Yemen
            </option>
            <option value="Zambia">Zambia
            </option>
            <option value="Zimbabwe">Zimbabwe
            </option>
          </select>
        </div>
      </div>
      <section id="accordion-with-margin">
        <div class="row">
          <div class="col-sm-12">
            <div class="card collapse-icon accordion-icon-rotate">
              <div class="card-body">
                <div class="accordion" id="accordionExample">
                  <div class="collapse-margin">
                    <div class="card-header" id="headingTwo" data-toggle="collapse" role="button" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span class="lead collapse-title">
                        Sub Product Two
                      </span>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div class="card-body">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                          <label for="category_id">Color</label>
                              <select name="color" class="form-control">

                              </select>
                        </div>
                          <div class="form-group col-md-6">
                            <label for="inputquantity">Quantity
                            </label>
                            <input type="text" name="quantity" class="form-control"  placeholder="Enter Quantity">
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <label for="inputprice">Price
                            </label>
                            <input type="text" name="price" class="form-control"  placeholder="Enter Price">
                          </div>
                          <div class="form-group col-md-6">
                            <label for="inputcurrency">Currency
                            </label>
                            <input type="text" name="currency_id" class="form-control"  placeholder="Enter Currency">
                          </div>
                        </div>
                        <div class="form-row">

                          <div class="form-group col-md-6">
                            <label for="inputsize">Show Size
                            </label>
                            <select name="show_size" class="form-control">
              								<option value="0">Yes</option>
              								<option value="1"> No </option>
              							</select>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <label for="inputShowColor">Show Color
                            </label>
                            <select name="show_color" class="form-control">
                              <option value="0">Yes</option>
                              <option value="1"> No </option>
                            </select>
                          </div>
                          <div class="form-group" style="margin:  10px 0px;">
                            <label for="img_id" class="col-md-4 control-label">Image
                            </label>
                            <div class="col-md-8">
                              <input type="file" required name="img" required="required" onchange="readURL(this);" />
                              <img id="blah" src="#" alt="your image" style=" display: none" />
                            </div>
                          </div>
                        </div>
                        <div class="form-group" style="margin:  10px 0px;">
                          <div class="col-sm-offset-4 col-sm-8">
                            <button type="submit" class="btn btn-dark">Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br>
     <br>
     <div class="price-actions float-left">
       <button form="ngForm" class="btn btn-brand btn-icon-sm" color="primary"
       class="btn btn-dark text-uppercase rounded-0" type="submit"
       (click)="onSubmit()">Sumbit</button>
     </div>
    </div>
  </div>
</div>
