import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  BASEURL = environment.baseURL;
  requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    })
  };
  constructor(
    private http: HttpClient
  ) { }
  search(key: any) {
    const body = new HttpParams()
      .set('word', key);
    return this.http.post(environment.baseURL+`/Product/search`,body);
  }
}
