<footer>
    <div class="footer text-center" style="position: relative">
        <div class="container m-auto">
            <ul class="row list-inline padding-top-md-106">
                <li class="list-inline-item dropup" style="position: static">
                    <a class="dropdown-toggle" data-toggle="dropdown">
                        <i class="fas fa-chevron-up" style="font-size: 12px; color: #000"
                            aria-hidden="true"></i><br /><span class="footer-break-word">
                            {{'footer.company' | translate}}
                        </span> </a>
                    <div class="dropdown-menu ddmenu">
                        <div class="w-100 m-auto" style="display: block; margin-bottom: 20px !important">
                            <div class="w-100 text-center">
                                <div class="m-v-20">{{'footer.company' | translate}}</div>
                                <button type="button" class="close">
                                    <i class="fas fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <ul class="ffff nav nav-tabs d-flex justify-content-center" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/about">{{'about_us_page.about_title' |
                                    translate}}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"
                                    routerLink="/about/policy-and-privacy">{{'about_us_page.privacy_title' |
                                    translate}}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/about/parteners">{{'about_us_page.parteners_title' |
                                    translate}}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/about/legal">{{'about_us_page.legal_title' |
                                    translate}}</a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="list-inline-item dropup" style="position: static">
                    <a class="dropdown-toggle" data-toggle="dropdown">
                        <i class="fas fa-chevron-up" aria-hidden="true"></i><br />
                        <span class="footer-break-word"> {{'footer.follow_us' | translate}} </span> </a>
                    <div class="dropdown-menu ddmenu">
                        <div class="w-100 m-auto" style="display: block; margin-bottom: 20px !important">
                            <div class="w-100 text-center">
                                <h4 class="m-v-20">{{'footer.follow_us' | translate}}</h4>
                                <button type="button" class="close">
                                    <i class="fas fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <ul class="list-inline social-media-icons">
                            <li class="list-inline-item">
                                <a [href]="socialData.facebook" target="_blank">
                                    <i class="fab fa-facebook fa-lg" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a [href]="socialData.twitter" target="_blank">
                                    <i class="fab fa-twitter fa-lg" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a [href]="socialData.instagram" target="_blank"><i class="fab fa-instagram fa-lg"
                                        aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="list-inline-item dropup" style="position: static">
                    <a class="dropdown-toggle" data-toggle="dropdown">
                        <i class="fas fa-chevron-up" aria-hidden="true"></i><br />
                        <span class="footer-break-word"> {{'footer.customer_services' | translate}} </span> </a>
                    <div class="dropdown-menu ddmenu">
                        <div class="w-100 m-auto" style="display: block; margin-bottom: 0px !important">
                            <div class="w-100 text-center">
                                <h4 class="m-v-20">{{'contact_us.customer_services_title' | translate}}</h4>
                                <button type="button" class="close">
                                    <i class="fas fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <ul class="ffff nav nav-tabs d-flex justify-content-center" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/customer-service/Contact">{{'contact_us.contact_us' |
                                    translate}}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/customer-service/payment">{{'contact_us.payment' |
                                    translate}}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/customer-service/Shipping">{{'contact_us.Shipping' |
                                    translate}}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"
                                    routerLink="/customer-service/exchange">{{'contact_us.Return_and_exchange' |
                                    translate}} </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/customer-service/FAQs" aria-controls="FAQs"
                                    aria-selected="false">{{'contact_us.FAQs' | translate}}</a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            <div class="col-12">
                <p class="copyright">
                    {{'footer.copyright' | translate}}
                </p>
            </div>
        </div>
    </div>
</footer>