<div class="account-info-and-cover">
    <div class="account-cover">
    </div>
    <div class="main-info text-center">
        <div class="letters-of-name" style="text-transform: uppercase; font-size: 1.25em;">
            {{firstCharOne}}.{{firstCharTwo}}
        </div>
        <h3 class="h3"> {{fullName}} </h3>
        <br>
    </div>
</div>
<div class="account-menu">
    <div class="container">
        <button class="btn btn-dark d-block d-md-none account-menu-toggle"> {{'account_menu.Account_Menu' |
            translate}}</button>
        <ul class="list-unstyled" style="text-align: center;">
            <li>
                <a routerLink="/account/overview" routerLinkActive="active"> {{'account_menu.Overview' | translate}}
                </a>
            </li>
            <li>
                <a routerLink="/account/profile-info" routerLinkActive="active"> {{'account_menu.Account' | translate}}
                </a>
            </li>
            <li>
                <a routerLink="/account/orders" routerLinkActive="active"> {{'account_menu.Orders' | translate}} </a>
            </li>
            <li>
                <a routerLink="/account/favorite" routerLinkActive="active"> {{'account_menu.Wishlist' | translate}}
                </a>
            </li>
            <!-- <li >
              <a routerLink="/account/messages" routerLinkActive="active"> {{'account_menu.Messages' | translate}} </a>
          </li> -->
            <li>
                <a routerLink="/account/favorite-designers" routerLinkActive="active">
                    {{'account_menu.Favorite_Designer' | translate}} </a>
            </li>
            <!-- <li>
              <a routerLink="/account/orders" routerLinkActive="active"> {{'account_menu.Notifications' | translate}} </a>
          </li> -->
            <li (click)="logout()" style="cursor: pointer;">
                <a> {{'account_menu.Logout' | translate}} </a>
            </li>
        </ul>
    </div>
</div>
<div class="container">
    <router-outlet></router-outlet>
</div>