<div class="container min-vh-100">
    <div class="row  justify-content-center" style="padding: 7em 0;">
        <h3 class="text-muted text-center  w-100 mt-5">
            {{'contact_us.customer_services_title' | translate}}
        </h3>
        <p class="text-muted text-center w-100 ">
            {{'contact_us.can_we_help' | translate}}
        </p>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link" id="Contact-tab" data-toggle="tab" href="#Contact" role="tab"
                    routerLink="/customer-service/Contact" [ngClass]="{'active': active == 'Contact'}"
                    aria-controls="Contact" aria-selected="true">
                    {{'contact_us.contact_us' | translate}} </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="payment-tab" data-toggle="tab" href="#payment" role="tab"
                    routerLink="/customer-service/payment" [ngClass]="{'active': active == 'payment'}"
                    aria-controls="payment" aria-selected="false">
                    {{'contact_us.payment' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="Shipping-tab" data-toggle="tab" href="#Shipping" role="tab"
                    routerLink="/customer-service/Shipping" [ngClass]="{'active': active == 'Shipping'}"
                    aria-controls="Shipping" aria-selected="false">
                    {{'contact_us.Shipping' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="exchange-tab" data-toggle="tab" href="#exchange" role="tab"
                    routerLink="/customer-service/exchange" [ngClass]="{'active': active == 'exchange'}"
                    aria-controls="exchange" aria-selected="false">
                    {{'contact_us.Return_and_exchange' | translate}} </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="FAQs-tab" data-toggle="tab" href="#FAQs" role="tab"
                    routerLink="/customer-service/FAQs" [ngClass]="{'active': active == 'FAQs'}" aria-controls="FAQs"
                    aria-selected="false">
                    {{'contact_us.FAQs' | translate}} </a>
            </li>
        </ul>
        <div class="tab-content w-100 d-flex justify-content-center mt-4">
            <div class="col-6 tab-pane" [ngClass]="{'active': active == 'Contact'}" id="Contact" role="tabpanel"
                aria-labelledby="Contact-tab">
                <form class="col-12 contact-us-form" #contactUsForm="ngForm" (ngSubmit)="contactSubmit()">
                    <div class="form-group w-100">
                        <label for="formGroupExampleInput">{{'contact_us.issue' | translate}}</label>
                        <select class="form-control select-issues" name="issue" placeholder="Select an option"
                            [(ngModel)]="issueValue">
                            <option value="" disabled selected>{{'contact_us.select'|translate}}</option>
                            <option [value]="'other'">{{'contact_us.other'|translate}}</option>
                            <option [value]="issue.issu" *ngFor="let issue of customerServices.contact?.issus">
                                {{issue.issu}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group" *ngIf="logged != '1'">
                        <label for="formGroupExampleInput2">
                            {{'contact_us.email' | translate}} </label>
                        <input type="text" name="email" class="form-control" id="formGroupExampleInpu2"
                            placeholder="example@example.com" ngModel #email="ngModel" required email
                            [ngClass]="{'is-invalid':email.touched && email.invalid }">
                        <div *ngIf="email.touched && email.invalid" class="invalid-feedback">
                            <div *ngIf="email.errors.required"> {{'register_page.emailRequired' | translate}} </div>
                        </div>
                    </div>
                    <div *ngIf="issueValue == 'other'">
                        <div class="form-group">
                            <label for="formGroupExampleInput2">
                                {{'contact_us.subject' | translate}} </label>
                            <input name="subject" type="text" class="form-control" id="formGroupExampleInput2"
                                placeholder="{{'contact_us.email_placeholder' | translate}}" ngModel  #subject="ngModel" required
                                [ngClass]="{'is-invalid':subject.touched && subject.invalid }">
                            <div *ngIf="subject.touched && subject.invalid" class="invalid-feedback">
                                <div *ngIf="subject.errors.required"> {{'contact_us.email_placeholder' | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="formGroupExampleInput3">
                                {{'contact_us.message' | translate}} </label>
                            <textarea name="message" class="form-control" id="formGroupExampleInput3"
                                placeholder="{{'contact_us.message_placeholder' | translate}}" rows="3" cols="50"
                                ngModel #message="ngModel" required
                                [ngClass]="{'is-invalid':message.touched && message.invalid }"></textarea>
                            <div *ngIf="message.touched && message.invalid" class="invalid-feedback">
                                <div *ngIf="message.errors.required"> {{'contact_us.message_placeholder' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button type="submit" class="btn btn-dark rounded-0 my-5 py-2"
                            [disabled]="contactUsForm.invalid" style="width: 180px;">
                            <span *ngIf="loading" class="float-right spinner-border spinner-border-sm mt-1" role="status"
                                aria-hidden="true"></span>
                            {{'contact_us.submit' | translate}}</button>
                    </div>
                </form>
                <div class="col-12">
                    <h5 class="text-muted text-center mb-3 text-uppercase">
                        {{'contact_us.direct_contact' | translate}}
                    </h5>
                    <div class="text-center" [innerHTML]="customerServices.contact?.content"> </div>
                </div>
            </div>
            <div class="tab-pane" [ngClass]="{'active': active == 'payment'}" id="payment" role="tabpanel"
                aria-labelledby="payment-tab" [innerHTML]="customerServices.payment?.content">
            </div>
            <div class="tab-pane" [ngClass]="{'active': active == 'Shipping'}" id="Shipping" role="tabpanel"
                aria-labelledby="Shipping-tab" [innerHTML]="customerServices.shipping?.content">
            </div>
            <div class="tab-pane" [ngClass]="{'active': active == 'exchange'}" id="exchange" role="tabpanel"
                aria-labelledby="exchange-tab" [innerHTML]="customerServices.return?.content">
            </div>
            <div class="tab-pane" [ngClass]="{'active': active == 'FAQs'}" id="FAQs" role="tabpanel"
                aria-labelledby="FAQs-tab">
                <!-- <div class="panel-group" id="accordion">
          <div *ngFor="let item of this.faqs" class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse{{item.ID}}">{{item.Quez}}</a>
              </h4>
            </div>
            <div id="collapse{{item.ID}}" class="panel-collapse collapse in">
              <div class="panel-body">{{item.Answer}}</div>
            </div>
          </div>
        </div> -->
            </div>
        </div>
        <div class="col-6 tab-pane">
        </div>
    </div>
</div>
<ngx-snackbar [position]="'top-right'"></ngx-snackbar>