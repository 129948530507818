import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  userId = sessionStorage.getItem('userId');
  favouritProducts = JSON.parse(localStorage.getItem('favouritProducts')) || [];
  private FavSource = new BehaviorSubject<any>([]);
  Fav = this.FavSource.asObservable();
  constructor(private http: HttpClient) {}
  getProfileInfo() {
    return this.http.get(
      environment.baseURL + `/user/user_profile/` + this.userId
    );
  }
  editProfileInfo(data) {
    const body = new HttpParams()
      .set('title', data.title)
      .set('first_name', data.first_name)
      .set('last_name', data.last_name)
      .set('name', data.name)
      .set('email', data.email)
      .set('country_id', data.country_id)
      .set('city', data['city_id'])
      .set('address', data['address'])
      .set('address2', data['address2'])
      .set('country_code', data.country_code)
      .set('phone', data['phone'])
      .set('post_code', data['post_code']);
    return this.http.post(
      environment.baseURL + `/user/edit_profile/` + this.userId,
      body
    );
  }
  getAddress() {
    return this.http.get(
      environment.baseURL + `/User/user_addresses/` + this.userId
    );
  }
  addNewAddress(data) {
    const body = new HttpParams()
      .set('user_id', this.userId)
      .set('address', data['address'])
      .set('address2', data['address2']);
    return this.http.post(environment.baseURL + `/User/new_address`, body);
  }
  deleteAddress(id) {
    return this.http.get(environment.baseURL + `/User/delete_address/` + id);
  }
  defultAddress(id) {
    const body = new HttpParams()
      .set('user_id', this.userId)
      .set('address_id', id);
    return this.http.post(environment.baseURL + `/User/default_address`, body);
  }
  addFavourits(data) {
    const body = new HttpParams()
      .set('user_id', this.userId)
      .set('product_id', data.id)
      .set('like', data.like);
    return this.http.post(environment.baseURL + `/Product/favourite`, body);
  }
  getFavourits() {
    return this.http.get(
      environment.baseURL + `/Product/user_likes/` + this.userId
    );
  }
  favDetails() {
    this.http
      .get(environment.baseURL + `/Product/user_likes/` + this.userId)
      .subscribe((res) => {
        this.FavSource.next(res);
      });
  }
  setDesignerFavourit(data) {
    const body = new HttpParams()
      .set('user_id', this.userId)
      .set('designer_id', data.id)
      .set('like', data.like);
    return this.http.post(environment.baseURL + `/Home/designer_like`, body);
  }
  getDesignerFavourit() {
    return this.http.get(
      environment.baseURL + `/Home/user_designer_likes/` + this.userId
    );
  }
  getOrders() {
    return this.http.get(
      environment.baseURL + `/Order/user_orders/` + this.userId
    );
  }
}
