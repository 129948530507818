import { LangService } from './../../../services/lang/lang.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-company-menu',
  templateUrl: './about-company-menu.component.html',
  styleUrls: ['./about-company-menu.component.scss']
})
export class AboutCompanyMenuComponent implements OnInit {
  websiteLang
  constructor(public translate: LangService) {
    this.websiteLang = this.translate.language;
  }

  ngOnInit(): void {
  }

}
