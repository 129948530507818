import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private http: HttpClient) {}
  menuData() {
    return this.http.get<any>(environment.baseURL + `/home/menu`);
  }
  getBrandTypes(){
    return this.http.get(environment.baseURL+`/home/designer_types`)
  }
  getHomeData() {
    return this.http.get(environment.baseURL + `/home/home`);
  }
  getFooterData() {
    return this.http.get(environment.baseURL+ `/home/footer`);
  }
  contactUs(data){
    const body = new HttpParams()
    .set('email', data.email)
    .set('issue', data.issue)
    .set('message',data.message)
    return this.http.post(environment.baseURL+`/home/contact_us`, body)
  }
}
