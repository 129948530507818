import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit {
  @ViewChild('main', { static: true }) mainDiv: ElementRef;
  constructor(private render: Renderer2, private router: Router) {
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          if (val.url == '/' || val.url == '/home') {
            this.render.addClass(this.mainDiv.nativeElement, 'homepage');
            this.render.addClass(this.mainDiv.nativeElement, 'm-0');
          } else {
            this.render.removeClass(this.mainDiv.nativeElement, 'homepage');
            this.render.removeClass(this.mainDiv.nativeElement, 'm-0');
          }
        }
      });
  }
  ngOnInit(): void {}
  onActivate(e) {
    window.scroll(0, 0);
  }
}
