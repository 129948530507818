import { LangService } from './services/lang/lang.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'brwaz';
  isLogged = sessionStorage.getItem('logged');
  userId = sessionStorage.getItem('userId');
  constructor(
    public router: Router,
    public translate: TranslateService,
    private langService: LangService
  ) {}
  ngOnInit() {
    this.langService.init();
    if (this.isLogged && this.userId) {
      console.log('logged in');
    } else {
      console.log('logged out');
      let myuuid = uuidv4();
      sessionStorage.setItem('userId', myuuid);
      sessionStorage.setItem('logged', '0');
    }
  }
  onActivate(e) {
    window.scroll(0, 0);
  }
}
