import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from './../../services/search/search.service';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  products: any;
  searchKey: string;
  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((param) => {
      this.searchKey = param.get('key');
      this.search();
    });
  }
  search() {
    this.searchService.search(this.searchKey).subscribe((res) => {
      this.products = res;
    });
  }
}
