<div class="survey">
    <div class="survey-form">
        <div class="container">
            <h2 class="h2 shopping-bag-title text-uppercase"> {{'survey.question_one'|translate}} </h2>
            <div class="shopping-bag-body">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div class="left-side">
                          <form>
                            <div class="form-group">
                              <label for="exampleFormControlSelect1">Example select</label>
                              <select class="form-control" id="exampleFormControlSelect1">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                            <div class="price-actions float-left">
                              <button form="ngForm" class="btn btn-brand btn-icon-sm" color="primary"
                              class="btn btn-dark text-uppercase rounded-0" type="submit"
                              (click)="onSubmit()">{{'survey.next_step'|translate}} </button>
                            </div>
                          </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
