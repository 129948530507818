import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-product-image',
    templateUrl: './product-image.component.html',
    styleUrls: ['./product-image.component.scss']
})
export class ProductImageComponent implements OnInit {

    imgBase = environment.imgURL;
    @Input() mainImage;
    @Input() number;
    @Input() index = 0;


    // On Hover
    top: any;
    top2: any;
    left: any;
    left2: any;


    constructor(
        private renderer: Renderer2,
        private lightbox: Lightbox,
    ) { }

    ngOnInit(): void {
    }
    @ViewChild('cursor') cursor: ElementRef;
    @ViewChild('cursor2') cursor2: ElementRef;



    @HostListener('mouseenter', ['$event'])
    onMouseEnter() {
        this.renderer.addClass(this.cursor.nativeElement, 'cursor');
        this.renderer.addClass(this.cursor2.nativeElement, 'cursor2');
    }

    @HostListener('mousemove', ['$event'])
    onMousemove($event) {
        this.top = ($event.offsetY) + "px";
        this.top2 = ($event.offsetY + 10) + "px";
        this.left = ($event.offsetX) + "px";
        this.left2 = ($event.offsetX + 50) + "px";
    }


    @HostListener('mouseout', ['$event'])
    onMouseOut() {
        this.renderer.removeClass(this.cursor.nativeElement, 'cursor');
        this.renderer.removeClass(this.cursor2.nativeElement, 'cursor2');
    }


    openLightbox(): void {
        console.log(this.mainImage);
        let arr = []
        this.mainImage.forEach(src => {
            arr = [...arr, { src, thumb: '' }]
        });
        this.lightbox.open(arr, this.index, {
            centerVertically: true,
            positionFromTop: 0,
            fitImageInViewPort: true,
            disableScrolling: true,
            wrapAround: true,
            showZoom: true,
            showRotate: false,
            showImageNumberLabel: true,
            albumLabel: "%1 | %2"
        });
    }


}
