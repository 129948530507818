<img [src]="imgBase+legal.image" style="width: 100%" *ngIf="legal.image != 'no'" />
<div class="container" style="margin-top: 50px; margin-bottom: 50px">
  <div class="row">
    <div class="col-md-12 text-left" style="
        border-bottom: 1px solid;
        border-top: solid 1px;
        padding: 40px 10px;
        max-width: 850px;
        margin: auto auto;
      " [innerHTML]="legal.content"></div>
  </div>
</div>
