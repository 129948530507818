<div class="survey">
    <div class="survey-form">
        <div class="container">
            <h2 class="h2 shopping-bag-title text-uppercase"> {{'survey.question_two'|translate}} </h2>
            <!-- <p class="shopping-bag-brief">
                You are currently in the United Arab Emirates store. Shipping to a different location?
            </p> -->
            <div class="shopping-bag-body">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div class="left-side">
                          <form>
                            <div class="form-group">
                              <label for="exampleFormControlSelect1">Example select</label>
                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                                <label class="form-check-label" for="inlineRadio1">1</label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                                <label class="form-check-label" for="inlineRadio2">2</label>
                              </div>
                            </div>
                            <div class="price-actions float-left">
                              <button form="ngForm" class="btn btn-brand btn-icon-sm" color="primary"
                              class="btn btn-dark text-uppercase rounded-0" type="submit"
                              (click)="onSubmit()">{{'survey.next_step'|translate}} </button>
                            </div>
                          </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
