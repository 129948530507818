import { AccountMenuComponent } from './account-menu/account-menu.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WishlistComponent } from './wishlist/wishlist.component';
import { AccountOverviewComponent } from './account-overview/account-overview.component';
import { UserMessagesComponent } from './user-messages/user-messages.component';
import { FavoriteListComponent } from './favorite-list/favorite-list.component';
import { AccountOrdersComponent } from './account-orders/account-orders.component';
import { AccountComponent } from './account/account.component';
import { DesignersFavoriteListComponent } from './designers-favorite-list/designers-favorite-list.component';
const routes: Routes = [
  {
    path: '',
    component: AccountMenuComponent,
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      { path: 'overview', component: AccountOverviewComponent },
      { path: 'profile-info', component: AccountComponent },
      { path: 'orders', component: AccountOrdersComponent },
      { path: 'favorite', component: FavoriteListComponent },
      {
        path: 'favorite-designers',
        component: DesignersFavoriteListComponent,
      },
      { path: 'messages', component: UserMessagesComponent },
      { path: 'wishlist', component: WishlistComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
