import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-share-product',
  templateUrl: './share-product.component.html',
  styleUrls: ['./share-product.component.scss'],
})
export class ShareProductComponent implements OnInit {
  imgBase = environment.imgURL;
  @Input() productData = {} as any;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
