import { AuthService } from './../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { LangService } from './../../../services/lang/lang.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SnackbarService } from 'ngx-snackbar';
import { CartService } from 'src/app/services/cart/cart.service';
@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss'],
})
export class UserRegisterComponent implements OnInit {
  websiteLang;
  loading = false;
  model: any = {};
  checkEmail;
  checkUsername;
  EmailOrUsernameExists;
  isVisible = true;
  public account = {
    password: null,
  };
  public barLabel: string = 'Password strength:';
  @ViewChild('registerForm') registerForm: NgForm;
  checkUser: Object;
  constructor(
    private userRegister: AuthService,
    public translate: LangService,
    private router: Router,
    private snackbarService: SnackbarService,
    private cartService: CartService
  ) {
    this.websiteLang = this.translate.language;
  }
  ngOnInit() {
    this.model.country_code = '+966';
    this.model.country_id = "Saudi Arabia";
  }
  onSubmit() {
    this.loading = true;
    this.EmailOrUsernameExists = null;
    this.userRegister.usernameExsit(this.registerForm.value).subscribe(
      (res) => {
        this.checkUsername = res['status'];
        this.userRegister.emailExsit(this.registerForm.value).subscribe(
          (res) => {
            this.checkEmail = res['status'];
            if (this.checkUsername == false && this.checkEmail == false) {
              this.userRegister
                .resgisterUser(this.registerForm.value)
                .subscribe((res: any) => {
                  if (res.status == true) {
                    let old = sessionStorage.getItem('userId');
                    try {
                      this.cartService
                        .edit_order_creator(old, res['message'])
                        .subscribe((data) => {
                          console.log(data);
                        });
                    } catch (error) {
                      console.log(error);
                    }
                    this.login();
                  }
                });
            } else {
              this.EmailOrUsernameExists = 'Email or Username is Exists !';
              this.loading = false;
            }
          },
          (err) => {
            this.loading = false;
            this.EmailOrUsernameExists = ' Emails is Exists !';
          }
        );
      },
      (err) => {
        this.EmailOrUsernameExists = ' Username is Exists !';
        this.loading = false;
      }
    );
    if (this.EmailOrUsernameExists) {
      this.snackbarService.add({
        msg: this.EmailOrUsernameExists,
        timeout: 4000,
        background: '#eee',
        color: '#333',
        action: {
          text: 'x',
          color: 'red',
        },
      });
    }
  }
  login() {
    let body = {
      email: this.registerForm.control.get('email').value,
      password: this.registerForm.control.get('password').value,
    };
    this.userRegister.loginUser(body).subscribe((res: any) => {
      if (res['status'] == true) {
        sessionStorage.setItem('user', JSON.stringify(res['message']['user']));
        sessionStorage.setItem('userId', res['message']['user']['id']);
        sessionStorage.setItem('logged', '1');
        this.loading = false;
        this.router.navigate(['/home']).then(() => window.location.reload());
      }
    });
  }
  logout() {
    sessionStorage.removeItem('logged');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('user');
    setTimeout(function () {
      window.location.reload();
    });
  }
  showPass() {
    this.isVisible = false;
  }
  hiddenPass() {
    this.isVisible = true;
  }
}
