import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartRoutingModule } from './cart-routing.module';

import { ShippingStepOneComponent } from './shipping-step-one/shipping-step-one.component';
import { SercurePaymentComponent } from './sercure-payment/sercure-payment.component';
import { AnonyUserComponent } from './anony-user/anony-user.component';
import { ShippingBagComponent } from './shipping-bag/shipping-bag.component';
import { CartAsideComponent } from './cart-aside/cart-aside.component';
import { CartNavbarComponent } from './cart-navbar/cart-navbar.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        ShippingBagComponent,
        AnonyUserComponent,
        SercurePaymentComponent,
        ShippingStepOneComponent,
        CartAsideComponent,
        CartNavbarComponent,
    ],
    imports: [
        CommonModule,
        CartRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
})
export class CartModule { }
