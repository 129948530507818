import { AuthService } from './../../../services/auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  @ViewChild('forgetPass') forgetPass: NgForm;
  constructor(public modal: NgbActiveModal, private authService: AuthService) {}

  ngOnInit(): void {}
  forgetPassword() {
    this.authService.forgetPassword(this.forgetPass.value).subscribe((res) => {
      console.log(res);
    });
  }
}
