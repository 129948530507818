<!-- Account Menu -->
<!-- [Start account orders] -->
<div class="account-orders">
    <div class="container min-vh-100">
        <!-- Start purchases section -->
        <div class="purchases">
            <h3 class="text-uppercase"> {{'account.MY_PURCHASES' | translate}} <sup>( {{myCart?.length}} )</sup> </h3>
            <div class="all-orders">
                <div class="row">
                    <div class="col-12 col-md-6" *ngFor="let order of myCart">
                        <div class="order">
                            <div class="row" *ngFor="let item of order.items">
                                <div class="col-6 col-md-4">
                                    <div class="preview-order">
                                        <a [routerLink]="['/products/product', item.Product['id']]" class="fav">
                                            <img [src]="imgBase+item.Product.images[0]" style="object-fit: cover;" alt="...">
                                            <div class="wishitem-description">
                                                {{item.Product['name']}}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-6 col-md-8">
                                    <div class="order-info">
                                        <h6>#B{{order.id}}</h6>
                                        <p>
                                            {{'account.not_finalized_yet' | translate}}
                                        </p>
                                        <p>
                                            {{'products.Color' | translate}}
                                            {{item.Product.sub_products[0]['color']['color']}}<br>
                                            {{'products.Size' | translate}} {{item.size_id}}<br>
                                            {{'products.Quantity' | translate}} {{item.quantity}}<br>
                                            {{'products.Price' | translate}} {{item.price_id}}<br>
                                            <br>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
