import { Component, OnInit } from '@angular/core';
import { HomeService } from './../../../services/home/home.service';
@Component({
  selector: 'app-homepage-services',
  templateUrl: './homepage-services.component.html',
  styleUrls: ['./homepage-services.component.scss'],
})
export class HomepageServicesComponent implements OnInit {
  serviceData = {}  as any;
  constructor(private homeService: HomeService) {}
  ngOnInit() {
    this.getServiceData();
  }
  getServiceData() {
    this.homeService.getHomeData().subscribe((res: any) => {
      this.serviceData = res.services;
    });
  }
}
