import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './../../../services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart/cart.service';
import { ForgetPasswordComponent } from '../../register/forget-password/forget-password.component';
@Component({
  selector: 'app-anony-user',
  templateUrl: './anony-user.component.html',
  styleUrls: ['./anony-user.component.scss'],
})
export class AnonyUserComponent implements OnInit {
  loading = false;
  model: any = {};
  loginError;
  constructor(
    private CartService: CartService,
    private router: Router,
    private loginService: AuthService,
    private modalService: NgbModal
  ) {}
  ngOnInit(): void {
    if (sessionStorage.getItem('logged') == '1') {
      let user = sessionStorage.getItem('userId');
      this.submit_order(user, user);
    }
  }
  onSubmit() {
    console.log(this.model);
    this.loading = true;
    this.loginService.loginUser(this.model).subscribe(
      (res: any) => {
        if (res['status'] == true) {
          let old = sessionStorage.getItem('userId');
          sessionStorage.setItem(
            'user',
            JSON.stringify(res['message']['user'])
          );
          sessionStorage.setItem('userId', res['message']['user']['id']);
          sessionStorage.setItem('logged', '1');
          console.log(res);
          this.loading = false;

          this.submit_order(old, res['message']['user']['id']);
        }
      },
      (err) => {
        this.loading = false;

        this.loginError = 'Email or password not correct.';
      }
    );
  }
  forgetPassword() {
    this.modalService
      .open(ForgetPasswordComponent, {
        centered: true,
        size: 'lg',
        keyboard: false,
        windowClass: 'animated fadeInDown',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }
  submit_order(oldUser, newUser) {
    this.CartService.edit_order_creator(oldUser, newUser).subscribe((data) => {
      this.router.navigateByUrl('cart/shipping-step-one');
      console.log(data);
    });
  }
}
