import { HomepageModule } from './../homepage/homepage.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutRoutingModule } from './about-routing.module';
import { AboutCompanyComponent } from './about-company/about-company.component';
import { PolicyAndPrivacyComponent } from './policy-and-privacy/policy-and-privacy.component';
import { LegalComponent } from './legal/legal.component';
import { PartenersComponent } from './parteners/parteners.component';
import { AboutCompanyMenuComponent } from './about-company-menu/about-company-menu.component';

@NgModule({
  declarations: [
    AboutCompanyComponent,
    PolicyAndPrivacyComponent,
    LegalComponent,
    PartenersComponent,
    AboutCompanyMenuComponent,
  ],
  imports: [CommonModule, AboutRoutingModule, TranslateModule.forChild(), HomepageModule],
})
export class AboutModule {}
