<!-- Account Menu -->

<!-- [Start favorite designer] -->
<!-- <div class="my-wishlist">
  <div class="container">
      <div class="wishlist-bar">
          <div class="row">
              <div class="col-12 col-md-6 col-lg-7">
                  <h4 class="h4 text-uppercase"> 1 Item </h4>
              </div>
              <div class="col-12 col-md-6 col-lg-5">
                  <div class="sharing-and-sorting">
                      <a href="#" class="btn btn-dark text-uppercase rounded-0"> <i class="fa fa-share"></i> Share
                          by email
                      </a>
                      <span class="sorting-title"> Sort By </span>
                      <select class="form-control">
                          <option value="title 1">Date</option>
                          <option value="title 2">Country</option>
                      </select>
                  </div>
              </div>
          </div>
      </div>
      <div class="all-wishlist">
          <div class="row">
              <div class="col-12 col-md-6 col-lg-4">
                  <div class="wishlist text-center">
                      <img src="assets/img/thumbnail-product-img.jpg" alt="..." title="...">
                      <div class="wishitem-description"> Detachable Quilted Hood Nylon Bomber Jacket </div>
                      <div class="wishlist-overly">
                          <p>
                              Detachable Quilted Hood Nylon Bomber Jacket
                          </p>
                          <div class="item-number">
                              <span>Item: </span>
                              <span>80121031</span>
                          </div>
                          <div class="color">
                              <span>Color: </span>
                              <span>Honey</span>
                          </div>
                          <div class="size">
                              <span>Size: </span>
                              <span>54</span>
                          </div>
                          <div class="links">
                              <a href="#" class="btn btn-dark rounded-0 w-100"> Call for inquiry </a>
                              <a href="#" class="btn btn-default rounded-0"> <i class="fa fa-share"></i> Share
                              </a>
                              <a href="#" class="btn btn-default rounded-0"> <i class="fa fa-info"></i> Full
                                  Details </a>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                  <div class="wishlist text-center">
                      <img src="assets/img/thumbnail-product-img.jpg" alt="..." title="...">
                      <div class="wishitem-description"> Detachable Quilted Hood Nylon Bomber Jacket </div>
                      <div class="wishlist-overly">
                          <p>
                              Detachable Quilted Hood Nylon Bomber Jacket
                          </p>
                          <div class="item-number">
                              <span>Item: </span>
                              <span>80121031</span>
                          </div>
                          <div class="color">
                              <span>Color: </span>
                              <span>Honey</span>
                          </div>
                          <div class="size">
                              <span>Size: </span>
                              <span>54</span>
                          </div>
                          <div class="links">
                              <a href="#" class="btn btn-dark rounded-0 w-100"> Call for inquiry </a>
                              <a href="#" class="btn btn-default rounded-0"> <i class="fa fa-share"></i> Share
                              </a>
                              <a href="#" class="btn btn-default rounded-0"> <i class="fa fa-info"></i> Full
                                  Details </a>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                  <div class="wishlist text-center">
                      <img src="assets/img/thumbnail-product-img.jpg" alt="..." title="...">
                      <div class="wishitem-description"> Detachable Quilted Hood Nylon Bomber Jacket </div>
                      <div class="wishlist-overly">
                          <p>
                              Detachable Quilted Hood Nylon Bomber Jacket
                          </p>
                          <div class="item-number">
                              <span>Item: </span>
                              <span>80121031</span>
                          </div>
                          <div class="color">
                              <span>Color: </span>
                              <span>Honey</span>
                          </div>
                          <div class="size">
                              <span>Size: </span>
                              <span>54</span>
                          </div>
                          <div class="links">
                              <a href="#" class="btn btn-dark rounded-0 w-100"> Call for inquiry </a>
                              <a href="#" class="btn btn-default rounded-0"> <i class="fa fa-share"></i> Share
                              </a>
                              <a href="#" class="btn btn-default rounded-0"> <i class="fa fa-info"></i> Full
                                  Details </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div> -->


<div class="my-wishlist">
  <div class="container">
      <div class="wishlist-bar">
          <div class="row">
              <!--                    <div class="col-12 col-md-6 col-lg-7">
                                      <h4 class="h4 text-uppercase"> 1 Item </h4>
                                  </div>-->

          </div>
      </div>
      <div class="all-wishlist">
          <div class="row">

                                  <div class="col-md-3" style="margin-top: 10px;">
                      <div class="card card-inverse card-primary border-none ">
                                                      <div class="col-md-12">
                              <div class="wishlist text-center">
                                  <div class="wishlist-equal-image">
                                      <img style="object-fit: cover;" src="https://brwaz.com/uploads/5f84958337242pexels-photo-1261422.jpeg" alt="..." title="...">
                                  </div>
                                  <div class="wishitem-description">
                                          B1                                    </div>
                                  <div class="wishlist-overly">
                                      <p>
                                          B1                                            <a href="https://brwaz.com/Favoriet/remove/24/en" id="pr180">

                                          </a>

                                      </p>
                                      <!-- <div class="item-number">
                                          <span>Item: </span>
                                          <span>80121031</span>
                                      </div>
                                      <div class="color">
                                          <span>Color: </span>
                                          <span>Honey</span>
                                      </div>
                                      <div class="size">
                                          <span>Size: </span>
                                          <span>54</span>
                                      </div> -->
                                      <div class="links">
                                          <a href="#" class="btn btn-dark rounded-0 w-100" style=" display: none;"> Call for inquiry                                            </a>
                                          <a href="#" class="btn btn-default rounded-0" onclick="sliderr1()"> <i class="fa fa-share" aria-hidden="true"></i> <br>Share                                            </a>
                                          <a href="https://brwaz.com/products/product/b1/en" class="btn btn-default rounded-0"> <i class="fa fa-info" aria-hidden="true"></i><br> Full Details </a>
                                      </div>
                                  </div>
                              </div>
                          </div>


                      </div>
                  </div>
                  <div id="sll1" class="w3-content w3-display-container" style="background: rgba(193, 193, 193, 0.86); display: none; position: absolute; left: 0px;">

                      <div style="
                           max-width: 700px;
                           width: 100%;
                           height: 450px;
                           margin: auto;
                           background: #fff;

                           ">
                          <button onclick="closeSlider1()" type="button" class="close" style=" position: relative; top: auto; right: auto; left:auto; float: right;  ">

                              <i class="fas fa-times" aria-hidden="true"></i>
                          </button>
                          <div id="firstImg" class="imgdev" style=" background-image: url(https://brwaz.com/uploads/5f84958337242pexels-photo-1261422.jpeg); background-size: cover; width: 45%; height: 100%; float: left; ">
                          </div>

                          <div style="text-align: center; width: 55%; height:100%; margin-top: 20%; float: left; ">
                              <h5>Share Tis Item</h5><br>
                              <h4>B1</h4><br>
                              <!--<a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-text="yes" data-via="brwaz" data-hashtags="brwaz" data-related="brwaz,brwaz" data-dnt="true" data-show-count="false">Tweet</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>-->

                              <a onclick="window.open('https://twitter.com/intent/tweet?hashtags=brwaz&amp;ref_src=twsrc%5Etfw&amp;related=brwaz%2Cbrwaz&amp;text=B1&amp;tw_p=tweetbutton&amp;url=http%3A%2F%2Flocalhost%2Fberwaz%2Fproducts%2Fproduct%2Fproduct1%2Fen%23&amp;via=brwaz',
                                              'newwindow',
                                              'width=500,height=350');
                                      return false;" target="_blank" href="window.open('https://twitter.com/intent/tweet?hashtags=brwaz&amp;ref_src=twsrc%5Etfw&amp;related=brwaz%2Cbrwaz&amp;text=B1&amp;tw_p=tweetbutton&amp;url=http%3A%2F%2Flocalhost%2Fberwaz%2Fproducts%2Fproduct%2Fproduct1%2Fen%23&amp;via=brwaz" class="btn" id="b"><i class="fab fa-twitter" aria-hidden="true"></i></a>



                      <!-- <button class="w3-button w3-black w3-display-left" onclick="plusDivs(-1)"></button>
                      <button class="w3-button w3-black w3-display-right" onclick="plusDivs(1)">&#10095;</button> -->
                  </div>
                  <script>

                      function sliderr1() {
                          if (document.getElementById("sll1").style.display === "none") {
                              document.getElementById("sll1").style.display = "flex";
                          }
                      }

                      function closeSlider1() {
                          document.getElementById("sll1").style.display = "none";
                      }
                  </script>
                      <div class="col-md-3" style="margin-top: 10px;">
                      <div class="card card-inverse card-primary border-none ">
                                                      <div class="col-md-12">
                              <div class="wishlist text-center">
                                  <div class="wishlist-equal-image">
                                      <img style="object-fit: cover;" src="https://brwaz.com/uploads/5f848c224b782photo-1517502166878-35c93a0072f0.jpg" alt="..." title="...">
                                  </div>
                                  <div class="wishitem-description">
                                          B2                                    </div>
                                  <div class="wishlist-overly">
                                      <p>
                                          B2                                            <a href="https://brwaz.com/Favoriet/remove/25/en" id="pr181">

                                          </a>

                                      </p>
                                      <!-- <div class="item-number">
                                          <span>Item: </span>
                                          <span>80121031</span>
                                      </div>
                                      <div class="color">
                                          <span>Color: </span>
                                          <span>Honey</span>
                                      </div>
                                      <div class="size">
                                          <span>Size: </span>
                                          <span>54</span>
                                      </div> -->
                                      <div class="links">
                                          <a href="#" class="btn btn-dark rounded-0 w-100" style=" display: none;"> Call for inquiry                                            </a>
                                          <a href="#" class="btn btn-default rounded-0" onclick="sliderr3()"> <i class="fa fa-share" aria-hidden="true"></i> <br>Share                                            </a>
                                          <a href="https://brwaz.com/products/product/b2/en" class="btn btn-default rounded-0"> <i class="fa fa-info" aria-hidden="true"></i><br> Full Details </a>
                                      </div>
                                  </div>
                              </div>
                          </div>


                      </div>
                  </div>
                  <div id="sll3" class="w3-content w3-display-container" style="background: #c1c1c1db; display: none; position: absolute;

                       left: 0px; ">

                      <div style="
                           max-width: 700px;
                           width: 100%;
                           height: 450px;
                           margin: auto;
                           background: #fff;

                           ">
                          <button onclick="closeSlider3()" type="button" class="close" style=" position: relative; top: auto; right: auto; left:auto; float: right;  ">

                              <i class="fas fa-times" aria-hidden="true"></i>
                          </button>
                          <div id="firstImg" class="imgdev" style=" background-image: url(https://brwaz.com/uploads/5f848c224b782photo-1517502166878-35c93a0072f0.jpg); background-size: cover; width: 45%; height: 100%; float: left; ">
                          </div>

                          <div style="text-align: center; width: 55%; height:100%; margin-top: 20%; float: left; ">
                              <h5>Share Tis Item</h5><br>
                              <h4>B2</h4><br>
                              <!--<a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-text="yes" data-via="brwaz" data-hashtags="brwaz" data-related="brwaz,brwaz" data-dnt="true" data-show-count="false">Tweet</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>-->

                              <a onclick="window.open('https://twitter.com/intent/tweet?hashtags=brwaz&amp;ref_src=twsrc%5Etfw&amp;related=brwaz%2Cbrwaz&amp;text=B2&amp;tw_p=tweetbutton&amp;url=http%3A%2F%2Flocalhost%2Fberwaz%2Fproducts%2Fproduct%2Fproduct1%2Fen%23&amp;via=brwaz',
                                              'newwindow',
                                              'width=500,height=350');
                                      return false;" target="_blank" href="window.open('https://twitter.com/intent/tweet?hashtags=brwaz&amp;ref_src=twsrc%5Etfw&amp;related=brwaz%2Cbrwaz&amp;text=B2&amp;tw_p=tweetbutton&amp;url=http%3A%2F%2Flocalhost%2Fberwaz%2Fproducts%2Fproduct%2Fproduct1%2Fen%23&amp;via=brwaz" class="btn" id="b"><i class="fab fa-twitter" aria-hidden="true"></i></a>


                      <!-- <button class="w3-button w3-black w3-display-left" onclick="plusDivs(-1)"></button>
                      <button class="w3-button w3-black w3-display-right" onclick="plusDivs(1)">&#10095;</button> -->
                  </div>
                  <script>

                      function sliderr3() {
                          if (document.getElementById("sll3").style.display === "none") {
                              document.getElementById("sll3").style.display = "flex";
                          }
                      }

                      function closeSlider3() {
                          document.getElementById("sll3").style.display = "none";
                      }
                  </script>
                      <div class="col-md-3" style="margin-top: 10px;">
                      <div class="card card-inverse card-primary border-none ">
                                                      <div class="col-md-12">
                              <div class="wishlist text-center">
                                  <div class="wishlist-equal-image">
                                      <img style="object-fit: cover;" src="https://brwaz.com/uploads/5f84953ef3dcbpexels-photo-428338.jpeg" alt="..." title="...">
                                  </div>
                                  <div class="wishitem-description">
                                          B3                                    </div>
                                  <div class="wishlist-overly">
                                      <p>
                                          B3                                            <a href="https://brwaz.com/Favoriet/remove/26/en" id="pr182">

                                          </a>

                                      </p>
                                      <!-- <div class="item-number">
                                          <span>Item: </span>
                                          <span>80121031</span>
                                      </div>
                                      <div class="color">
                                          <span>Color: </span>
                                          <span>Honey</span>
                                      </div>
                                      <div class="size">
                                          <span>Size: </span>
                                          <span>54</span>
                                      </div> -->
                                      <div class="links">
                                          <a href="#" class="btn btn-dark rounded-0 w-100" style=" display: none;"> Call for inquiry                                            </a>
                                          <a href="#" class="btn btn-default rounded-0" onclick="sliderr5()"> <i class="fa fa-share" aria-hidden="true"></i> <br>Share                                            </a>
                                          <a href="https://brwaz.com/products/product/b3/en" class="btn btn-default rounded-0"> <i class="fa fa-info" aria-hidden="true"></i><br> Full Details </a>
                                      </div>
                                  </div>
                              </div>
                          </div>


                      </div>
                  </div>
                  <div id="sll5" class="w3-content w3-display-container" style="background: #c1c1c1db; display: none; position: absolute;

                       left: 0px; ">

                      <div style="
                           max-width: 700px;
                           width: 100%;
                           height: 450px;
                           margin: auto;
                           background: #fff;

                           ">
                          <button onclick="closeSlider5()" type="button" class="close" style=" position: relative; top: auto; right: auto; left:auto; float: right;  ">

                              <i class="fas fa-times" aria-hidden="true"></i>
                          </button>
                          <div id="firstImg" class="imgdev" style=" background-image: url(https://brwaz.com/uploads/5f84953ef3dcbpexels-photo-428338.jpeg); background-size: cover; width: 45%; height: 100%; float: left; ">
                          </div>

                          <div style="text-align: center; width: 55%; height:100%; margin-top: 20%; float: left; ">
                              <h5>Share Tis Item</h5><br>
                              <h4>B3</h4><br>
                              <!--<a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-text="yes" data-via="brwaz" data-hashtags="brwaz" data-related="brwaz,brwaz" data-dnt="true" data-show-count="false">Tweet</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>-->

                              <a onclick="window.open('https://twitter.com/intent/tweet?hashtags=brwaz&amp;ref_src=twsrc%5Etfw&amp;related=brwaz%2Cbrwaz&amp;text=B3&amp;tw_p=tweetbutton&amp;url=http%3A%2F%2Flocalhost%2Fberwaz%2Fproducts%2Fproduct%2Fproduct1%2Fen%23&amp;via=brwaz',
                                              'newwindow',
                                              'width=500,height=350');
                                      return false;" target="_blank" href="window.open('https://twitter.com/intent/tweet?hashtags=brwaz&amp;ref_src=twsrc%5Etfw&amp;related=brwaz%2Cbrwaz&amp;text=B3&amp;tw_p=tweetbutton&amp;url=http%3A%2F%2Flocalhost%2Fberwaz%2Fproducts%2Fproduct%2Fproduct1%2Fen%23&amp;via=brwaz" class="btn" id="b"><i class="fab fa-twitter" aria-hidden="true"></i></a>


                      <!-- <button class="w3-button w3-black w3-display-left" onclick="plusDivs(-1)"></button>
                      <button class="w3-button w3-black w3-display-right" onclick="plusDivs(1)">&#10095;</button> -->
                  </div>
                  <script>

                      function sliderr5() {
                          if (document.getElementById("sll5").style.display === "none") {
                              document.getElementById("sll5").style.display = "flex";
                          }
                      }

                      function closeSlider5() {
                          document.getElementById("sll5").style.display = "none";
                      }
                  </script>
              </div>
      </div>
  </div>
</div>

<!-- [End favorite designer] -->
