<!-- Account Menu -->
<div class="container-fluid page-container">
  <div class="row p-list" style="padding: 61px 20px 0px;">
    <!-- <h6 >Jackets</h6>-->
  </div>
  <div class="row p-list">
    <div class="col-md-3" *ngFor="let item of brandsFavoriteList">
      <div class="card card-inverse card-primary border-none ">
        <a [routerLink]="['/brand', item.id]">
          <img [src]="imgBase+item.image" alt="">
        </a>
        <blockquote class="card-blockquote pt-3 pb-3">
          <div class="favourites-product-container">
            <i class="fas fa-heart" aria-hidden="true" (click)="removeDesignerFromFav(item.id)"></i>
          </div>
          <div class="product-card-title">
            <a [routerLink]="['/brand', item.id]">
              <p class="p-text-ps"> {{item.name}} </p>
            </a>
          </div>
        </blockquote>
      </div>
    </div>
    <div class="col-12" *ngIf="brandsFavoriteList.length == 0">
      <div class="container">
        <div class="alert alert-warning">
          <b> {{'products_page.warning_msg' | translate}} </b>
        </div>
      </div>
    </div>
  </div>
</div>
