<nav class="navbar navbar-expand-lg navbar-light main-navbar">
  <div class="container" style="padding:0px;">
    <a routerLink="/" class="return-to-store" title="return to store">{{'cart.RETURN_TO_STORE'|translate}} </a>
    <a class="navbar-brand text-center" routerLink="/" style="margin-left: 100px;">
      <img src="assets/img/logo-b.png" class="img-fluid" />
    </a>
  </div>
</nav>
<div class="cart">
  <div class="cart-bullts">
    <div class="container">
      <div class="all-bullts">
        <span class="bullt" routerLink="/cart" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
            true}"> {{'cart.Shopping_bag'|translate}} 
        </span>
        <span class="bullt" routerLink="/cart/annonymous-login" routerLinkActive="active">
          {{'cart.Your_email'|translate}} 
        </span>
        <span class="bullt" routerLink="/cart/shipping-step-one" routerLinkActive="active">
          {{'cart.Shipping'|translate}}
        </span>
        <span class="bullt" routerLink="/cart/secure-payment" routerLinkActive="active">
          {{'cart.Secure_payment'|translate}}
        </span>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>