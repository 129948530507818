import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'where.component',
  templateUrl: './where.component.html',
  styleUrls: ['./where.component.scss'],
})
export class WhereComponent implements OnInit {
  loading = false;
  model: any = {};
  email: any;
  loginError;
  constructor(
    private router: Router
  ) {}
  ngOnInit(): void {
    if (sessionStorage.getItem('logged') == '1') {
       let user = sessionStorage.getItem('userId');
       console.log(user);
    }
  }

  onSubmit() {
    alert("Survey Done!");
    console.log(this.email);
  }

}
