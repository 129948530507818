import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
    providedIn: 'root',
})
export class CartService {
    BASEURL = environment.baseURL;
    userId = sessionStorage.getItem('userId');
    // All Meals
    private CartSource = new BehaviorSubject<any>([]);
    Cart = this.CartSource.asObservable();
    requestOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
    };
    constructor(private http: HttpClient) {
        this.cartDetails();
    }
    AddToCart(data) {
        let url = this.BASEURL + '/Order/add_item_order';
        const body = new HttpParams()
            .set('carancy', data['carancy'])
            .set('product_id', data['product_id'])
            .set('quantity', data['quantity'])
            .set('sub_product_id', data['sub_product_id'])
            .set('size_id', data['size_id'])
            .set('price_id', data['price_id'])
            .set('note', data['note'])
            .set('userId', this.userId);
        return this.http.post(url, body, this.requestOptions);
    }

    showAtHome(data) {
        let url = this.BASEURL + '/Order/show_item_at_home';
        const body = new HttpParams()
            .set('carancy', data['carancy'])
            .set('product_id', data['product_id'])
            .set('sub_product_id', data['sub_product_id'])
            .set('size_id', data['size_id'])
            .set('note', data['note'])
            .set('price_id', data['price_id'])
            .set('quantity', data['quantity'])
            .set('userId', this.userId);
        return this.http.post(url, body, this.requestOptions);
    }


    cartDetails() {
        let url = this.BASEURL + '/Order/cart/' + this.userId;
        this.http.get(url, this.requestOptions).subscribe((res) => {
            this.CartSource.next(res);
        });
    }
    edit_quantity(data) {
        let url = this.BASEURL + '/Order/update_order_item/' + data['id'];
        const body = new HttpParams()
            .set('price_id', data['price_id'])
            .set('quantity', data['quantity']);
        return this.http.post(url, body, this.requestOptions);
    }
    edit_profile(data) {
        let url = this.BASEURL + '/Order/shipping_data/' + data['id'];
        const body = new HttpParams()
            .set('title', data['title'])
            .set('full_name', data['full_name'])
            .set('last_name', data['last_name'])
            .set('country_code', data['country_code'])
            .set('phone', data['phone'])
            .set('address', data['address'])
            .set('address2', data['address2'])
            .set('city_id', data['city_id'])
            .set('post_code', data['post_code']);
        return this.http.post(url, body, this.requestOptions);
    }
    remove_item(id) {
        let url = this.BASEURL + '/Order/remove_order_item/' + id;
        return this.http.get(url, this.requestOptions);
    }
    edit_order_creator(oldUSer, newUser) {
        let url = this.BASEURL + '/Order/edit_order_creator';
        const body = new HttpParams().set('old', oldUSer).set('new', newUser);
        return this.http.post(url, body, this.requestOptions);
    }
    finishOrder(data) {
        let url = this.BASEURL + '/Order/finish_order'; 
        const body = new HttpParams()
            .set('user_id', data['user_id'])
            .set('status', data['status'])
            .set('total', data['total'])
            .set('payment', data['payment'])
            .set('cardnumber', data['cardnumber'])
            .set('cardname', data['cardname'])
            .set('cv', data['cv'])
            .set('month', data['month'])
            .set('year', data['year']);
            
        return this.http.post(url, body, this.requestOptions);
    }
    SavePaymentData(param){
        let url = this.BASEURL + '/Order/SavePaymentData'; 
        const body = new HttpParams()
            .set('user_id', param.user_id)
            .set('status', param.status)
            .set('invoiceID', param.invoiceID)
            .set('paymentId', param.paymentId);
        return this.http.post(url, body, this.requestOptions);
    }
}
