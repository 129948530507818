import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {

  constructor(private http: HttpClient) {}

  allProducts() {
    return this.http.get(environment.baseURL + `/Product/all_products`);
  }
  getSingleProduct(id) {
    return this.http.get(environment.baseURL + `/Product/single_product/` + id);
  }
  getCategoryProduct(id) {
    return this.http.get(environment.baseURL + `/Product/category/` + id);
  }
  getBrandData(id) {
    return this.http.get(
      environment.baseURL + `/Product/designer_products/` + id
    );
  }
  getPData(id) {
    return this.http.get(
      environment.baseURL + `/Product/pdata/` + id
    );
  }
  updatePData(data){
    const body = new HttpParams()
    .set('product_id', data.id)
     .set('name', data.name);
    // .set('size', data.size);
    // .set('color', data.color);
    // .set('color_ar', data.color_ar);


    return this.http.post(environment.baseURL + `/Product/pdata/` + data.id, body)
  }
  addQuestion(data){
    const body = new HttpParams()
    .set('product_id', data.id)
    .set('faq', data.faq);
    return this.http.post(environment.baseURL+`/Product/send_product_faq`, body)
  }
}
