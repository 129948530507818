<div class="helping">
    <h3 class="h3 helping-title"> {{'cart.HELP'|translate}} </h3>
    <div class="helping-chat">
        <i class="fa fa-comments-o fa-lg"></i>
        <a> {{'cart.Start_Chat'|translate}} </a>
    </div>
    <p> {{'cart.queries'|translate}}
       <a routerLink="/customer-service/Contact"> {{'contact_us.contact_us'|translate}}</a>
    </p>
</div>
<div class="shipping-payment">
    <h3 class="h3"> {{'cart.returns'|translate}} </h3>
    <p class="free-return">
        {{'cart.confidence'|translate}} <a routerLink="/customer-service/exchange">  {{'cart.free_returns'|translate}} </a>
    </p>
    <p>
        {{'cart.details'|translate}} <a routerLink="/customer-service/Shipping"> {{'cart.Shipping'|translate}}.</a>
    </p>
    <h3 class="h3 brwaz-accepts-title"> {{'cart.accept'|translate}} </h3>
    <div class="payment-platforms">
        <a></a>
        <a></a>
        <a></a>
        <a class="text-uppercase text-center"> {{'cart.cash'|translate}}</a>
    </div>
</div>