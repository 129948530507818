import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products/products.service';
import { AccountService } from 'src/app/services/account/account.service';
@Component({
  selector: 'app-products',
  templateUrl: './update_product.component.html',
  styleUrls: ['./update_product.component.scss'],
})
export class UpdateProductComponent implements OnInit {
  products: Array<any> = [];
  productsArr: Array<any> = [];
  proudctId: number;
  productData = {} as any;
  SubProducts: any = [];
  mainImages = [];
  Designer: any = {};
  productDataDefault: any = {};
  categoryColors: any = [];
  categorySize: any = [];
  countries: any = [];
  logged = sessionStorage.getItem('logged');
  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private productsService: ProductsService,
    private accountService: AccountService,
  ) {}
  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
        this.proudctId = +params.get('id')!;
        this.getProductData();
    });
  }
  onSubmit() {
    // this.router.navigateByUrl('');
  }
  getProductData() {
      this.spinner.show('sp');
      this.productsService.getSingleProduct(this.proudctId).subscribe((res) => {
          this.productData = res;
          if (this.logged != '1') {
              this.accountService.favouritProducts.forEach((element) => {
                  if (element.id == this.productData.id) {
                      this.productData.isFavourite = true;
                  }
              });
          }
          this.Designer = this.productData.designer;
          this.SubProducts = this.productData.sub_products;
          this.mainImages = [...this.productData['images']];
          // console.log(this.productData.category_id);
          let catId = this.productData.category_id;
          this.productsService.getPData(catId).subscribe((resCat) => {
              this.spinner.hide('sp');
              this.productDataDefault = resCat;
              this.categoryColors = [...this.productDataDefault['category_color']];
              this.categorySize = [...this.productDataDefault['category_size']];
              this.countries = [...this.productDataDefault['country']];
          });
      });
  }

}
