import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterRoutingModule } from './register-routing.module';
import { UserRegisterComponent } from './user-register/user-register.component';
import { PasswordStrengthBarComponent } from './password-strength-bar/password-strength-bar.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { SnackbarModule } from 'ngx-snackbar';
@NgModule({
  declarations: [
    UserRegisterComponent,
    PasswordStrengthBarComponent,
    ForgetPasswordComponent,
  ],
  imports: [CommonModule, RegisterRoutingModule, FormsModule, TranslateModule,SnackbarModule],
})
export class RegisterModule {}
