<div designerConfirmationForm="ngForm" (ngSubmit)="designerConfirmation()">
  <div class="modal-body text-center">
    <p>we have received your request and we will review it and get back to you via email.</p>
  </div>
  <div class="modal-footer border-none justify-content-center">
    <button type="button" (click)="modal.dismiss()" class="btn btn-dark">
      Cancel
    </button>
  </div>
</div>
