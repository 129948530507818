import { LangService } from './../lang/lang.service';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {
    lang = 'en';
    userId = 'brwaz';
    logged = sessionStorage.getItem('logged');
    constructor(private langService: LangService) {
        this.lang = this.langService.language;
        if (this.logged == '1') {
            this.userId = sessionStorage.getItem('userId');
        }
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        request = request.clone({
            headers: request.headers
                .append('lang', this.lang)
                .append('userId', this.userId),
        });
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            })
        );
    }
}
