import { CartNavbarComponent } from './cart-navbar/cart-navbar.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShippingStepOneComponent } from './shipping-step-one/shipping-step-one.component';
import { SercurePaymentComponent } from './sercure-payment/sercure-payment.component';
import { AnonyUserComponent } from './anony-user/anony-user.component';
import { ShippingBagComponent } from './shipping-bag/shipping-bag.component';
const routes: Routes = [
  {
    path: '',
    component: CartNavbarComponent,
    children: [
      { path: '', component: ShippingBagComponent },
      { path: 'annonymous-login', component: AnonyUserComponent },
      { path: 'shipping-step-one', component: ShippingStepOneComponent },
      { path: 'secure-payment', component: SercurePaymentComponent },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CartRoutingModule {}
