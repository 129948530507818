<div class="real-time-popup for-mobile" *ngIf="ViewBagPopup">
    <div class="my-cart-popup-realtime remove-before">
        <h3 class="h3 popup-realtime__heading text-center">{{'products.My_Cart' | translate}}</h3>
        <hr />
        <div class="popup-realtime__body">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="popup-realtime__product-img" style="margin-bottom: 15px">
                        <img [src]="imgBase+mainImages[0]" class="img-fluid" />
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <div class="popup-realtime__description">
                        <h5 class="h5">{{productData.name}}</h5>
                        <ul class="product-detailes-realttime list-unstyled">
                            <li>
                                <span class="realtime-subtitle color"> {{'products.Color' | translate}} </span>
                                <span> {{BagItem.color?.color}} </span>
                            </li>
                            <li>
                                <span class="realtime-subtitle price"> {{'products.Price' | translate}} </span>
                                <span>
                                    {{BagItem.price + " " + BagItem.currency_id}}
                                </span>
                            </li>
                            <li>
                                <span class="realtime-subtitle size"> {{'products.Size' | translate}} </span>
                                <span> {{BagItem.size?.size }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="realtime-footer">
            <button class="text-uppercase" [routerLink]="'/cart'">
                <a style="color: #ffffff !important; padding: 0">
                    {{'products.Checkout' | translate}}
                </a>
            </button>
        </div>
    </div>
</div>
<!-- [Start product details] -->
<div class="product-details">
    <div class="container">
        <div class="row" style="margin: 0">
            <table class="product-table gallery">
                <tbody>
                    <tr class="row" *ngIf="mainImages.length > 0">
                        <td class="col-12 col-md-6 custom-preview-product"
                            style="position: relative; overflow: hidden;">
                            <app-product-image *ngIf="mainImages[0]" [mainImage]="mainImages"
                                [number]="' 1/' +  mainImages.length" [index]="0">
                            </app-product-image>
                        </td>
                        <td class="col-12 col-md-6">
                            <div class="add-to-bag">
                                <div class="transaction-title">
                                    {{productData.name}}
                                </div>
                                <div class="designer">
                                    <!-- <span> {{'products.Artist' | translate}}</span> -->
                                    <span>
                                        {{(websiteLang == 'en') ? Designer.designer_type.name :
                                        Designer.designer_type.name_ar}}
                                    </span>
                                    <a href="#" data-toggle="modal" class="pro-designer float-opposite"
                                        data-target="#AboutModal">
                                        {{Designer.name + ' ' + Designer.name2}}
                                    </a>
                                    <div class="total-price">
                                        <div class="pb-bag col-md-12 p-0 pb-2" *ngIf="selectedSubProduct">
                                            <a id="vPrice">
                                                {{selectedSubProduct.price + " " + selectedSubProduct.currency_id}}
                                            </a>
                                            <span class="float-opposite">
                                                <i *ngIf="productData.isFavourite == true"
                                                    (click)="removeFromFavourit(productData.id)" class="fas fa-heart"
                                                    aria-hidden="true"></i>
                                                <i *ngIf="productData.isFavourite == false"
                                                    (click)="addToFavourit(productData.id)" class="far fa-heart"
                                                    aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="total-price">
                                    <div class="pb-bag col-md-12 p-0 row m-0">
                                        <div class="col-6 p-0 pt-3 pb-3"
                                            [ngClass]="{'color-bag': productData['show_size']}"
                                            *ngIf="productData['show_color']">
                                            <div class="color-title">
                                                {{'products.Color' | translate}}
                                            </div>
                                            <div class="color-word" (click)="openColorCollapse()"
                                                [attr.aria-expanded]="!colorCollapsed">
                                                {{selectedColor['color']}}
                                            </div>
                                        </div>
                                        <div class="col-6 pt-3 pb-3" *ngIf="productData['show_size']">
                                            <div class="color-title">
                                                {{'products.Size' | translate}}
                                            </div>
                                            <!-- (click)="!colorCollapsed && color_collapse.toggle(false)" -->
                                            <!-- (click)="Sizes.length > 1 && selectedColor['id'] != '' && size_collapse.toggle() " -->
                                            <div class="color-word" (click)="openSizeCollapse()" *ngIf="selectedSize['size']"
                                                [attr.aria-expanded]="!sizeCollapsed">
                                                {{selectedSize['size']}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="total-price">
                                    <div class="pb-bag col-md-12 p-0 row m-0">
                                        <div class="col-12 w-100 mt-2" #size_collapse="ngbCollapse"
                                            [(ngbCollapse)]="sizeCollapsed">
                                            <span class="sizzzzz active" *ngFor="let size of Sizes; let i = index"
                                                (click)="selectSize(i)">
                                                {{size.size}}
                                            </span>
                                            <a class=" find-size float-opposite mb-3"
                                                (click)="sizeGuid(productData)">{{'products.Size_guide' |
                                                translate}}</a>
                                        </div>
                                        <div class="col-12 w-100 mt-2" #color_collapse="ngbCollapse"
                                            [(ngbCollapse)]="colorCollapsed">
                                            <!-- (click)="select_color(color)" -->
                                            <a *ngFor="let color of Colors; let i = index" (click)="selectColor(i)">
                                                <span class="coooolor">
                                                    <img src="{{imgBase+color.image}}"
                                                        style="margin-bottom: 9px;width: 24px;height: 24px;border-radius: 50%;border: double;" />
                                                </span>
                                            </a>
                                        </div>
                                        <div  *ngIf="productData.show_size" class="col-12 w-100 pt-3 pb-3 ">
                                            <span class="float-opposite find-size mb-3" data-toggle="modal"
                                                data-target="#itemHistoryLabel" (click)="sizeGuid(productData)">
                                                {{'products.Find_my_size' | translate}}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="total-price"
                                    *ngIf="selectedSubProduct['id']  && selectedSubProduct['quantity'] > 0">
                                    <form class="pb-bag col-md-12 p-0 row m-0">
                                        <div class="col-6 p-0 pt-3 pb-3">
                                            <!-- <input type="number" style=" max-width: 90%" class="quantity_input"
                                                    [(ngModel)]="model['quantity']" (ngModelChange)="quantityChange()"
                                                    [max]="selectedSubProduct['quantity']" min="1" value="1" required=""
                                                    name="quantity" id="quantity" placeholder="Please select quantity"> -->
                                            <select name="q" class="form-control" [(ngModel)]="model['quantity']"
                                                (change)="quantityChange()" id="quan194">
                                                <option *ngFor="let item of arrayOne(selectedSubProduct['quantity'])"
                                                    [value]="item">
                                                    {{item}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-6 py-3 px-1">
                                            <button type="button" id="bag" (click)="AddToBag()"
                                                class="btn btn-dark px-3 rounded-0">
                                                {{'products.Add' | translate}}
                                                <span *ngIf="ViewBagLoder" class="spinner-border spinner-border-sm"
                                                    role="status" aria-hidden="true"></span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div class="total-price">
                                    <div class="pb-bag col-md-12 p-0 row m-0">
                                        <div class="col-6 p-0 pt-3 pb-3 color-bag text-center">
                                            <span class=" find-size mb-3" (click)="shareItem(productData)">
                                                {{'products.Share_this_item' | translate}}
                                            </span>
                                        </div>
                                        <div class="col-6 pt-3 pb-3 text-center">
                                            <span class=" find-size mb-3" (click)="showHome(productData)"
                                                *ngIf="productData['show_at_home']">
                                                {{'products.Watch_at_home' | translate}}
                                            </span>
                                            <div class="total-price text-center"
                                                *ngIf="selectedSubProduct['id']  && selectedSubProduct['quantity'] == 0">
                                                <span style="color: #b80000;font-size: 1.5em; font-weight: bold;">
                                                    {{'products.Sold_Out' | translate}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="row">
                        <td class="col-12 col-md-6">
                            <div class="description">
                                <div class="titlee text-uppercase">{{'products.description' | translate}}</div>
                                <div class="description-details">
                                    <p [innerHTML]="productData.description"></p>
                                </div>
                                <div class="faq">
                                    <h4 class="h4 text-uppercase">{{'products.faqs' | translate}}</h4>
                                    <form #faq="ngForm" (ngSubmit)="createQuestion()">
                                        <div class="form-group ask-textarea" [ngClass]="{'d-none': !FaqFormShow}">
                                            <textarea name="faq" class="form-control" id="full-featured"
                                                placeholder="{{'products.askfield'|translate}}" ngModel></textarea>
                                        </div>
                                        <div class="faq-actions text-right">
                                            <span [ngClass]="{'d-none': FaqFormShow}">
                                                {{'products.Got_a_question' | translate}} </span>
                                            <span [ngClass]="{'d-none': !FaqFormShow}">
                                                {{'products.Got_a_question_res' | translate}} </span>
                                            <a class="btn btn-light rounded-0 just-ask" (click)="FaqFormShow = true"
                                                [ngClass]="{'d-none': FaqFormShow}">
                                                {{'products.Just_Ask' | translate}} </a>
                                            <button type="submit" [ngClass]="{'d-none': !FaqFormShow}"
                                                class="btn btn-light rounded-0 text-uppercase ask"> {{'products.Ask' |
                                                translate}} </button>
                                            <span *ngIf="addloading"
                                                class="float-right spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                        </div>
                                    </form>
                                    <!-- FAQs -->
                                    <div *ngFor="let quiz of productData.FAQs">
                                        <div>
                                            <p style="font-weight: bold;" class="faq__question">
                                                {{quiz.faq}}
                                            </p>
                                            <p class="faq__answer" style=" margin:0.5em 0.7em; color: #444;"
                                                [innerHTML]="quiz.answer">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="col-12 col-md-6" *ngFor="let image of mainImages;let i = index"
                            [ngClass]="{'d-none': !mainImages[i + 1]}">
                            <app-product-image [mainImage]="mainImages" [number]="i+2 + ' /' +  mainImages.length"
                                [index]="i + 1">
                            </app-product-image>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-12 col-md-12">
                <!-- Start purchases history section -->
                <div id="accordion" class="purchases-history">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed text-uppercase" data-toggle="collapse"
                                    data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    {{'products.Exchange_Returns' | translate}}
                                    <div class="float-right">
                                        <i class="fa fa-plus float-right" aria-hidden="true"></i>
                                        <i class="fa fa-minus float-right" aria-hidden="true"></i>
                                    </div>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                                <div class="no-order-item text-uppercase" [innerHTML]="productData.exchange"> </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed text-uppercase" data-toggle="collapse"
                                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    {{'products.Shipping_Information' | translate}}
                                    <div class="float-right">
                                        <i class="fa fa-plus float-right" aria-hidden="true"></i>
                                        <i class="fa fa-minus float-right" aria-hidden="true"></i>
                                    </div>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div class="card-body" [innerHTML]="productData.shipping">
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed text-uppercase" data-toggle="collapse"
                                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    {{'products.Payment_Options' | translate}}
                                    <div class="float-right">
                                        <i class="fa fa-plus float-right" aria-hidden="true"></i>
                                        <i class="fa fa-minus float-right" aria-hidden="true"></i>
                                    </div>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                            data-parent="#accordion">
                            <div class="card-body" [innerHTML]="productData.payment">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End purchases history section -->
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="AboutModal" tabindex="-1" role="dialog" aria-labelledby="AboutModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header border-bottom-none text-center">
                <h5 class="modal-title modal-custom" id="AboutModalLabel">
                    {{Designer.name}}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body modal-body-custom text-center">
                <p></p>
            </div>
            <div class="modal-footer border-none">
                <a class="btn outline-button-modal btn-outline-secondary text-uppercase"
                    [routerLink]="['/brand',Designer.id]" data-dismiss="modal" aria-label="Close">
                    {{'products.go_to_designer_page' | translate}}
                </a>
            </div>
        </div>
    </div>
</div>
