import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllProductsRoutingModule } from './all-products-routing.module';
import { CategoriesComponent } from './categories/categories.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { ProductsComponent } from './products/products.component';
import { UpdateProductComponent } from './update_product/update_product.component';
import { ProductImageComponent } from './product-image/product-image.component';
import { LightboxModule } from 'ngx-lightbox';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ShareProductComponent } from './product-view/share-product/share-product.component';
import { ShowHomeComponent } from './product-view/show-home/show-home.component';
import { SizeGuideComponent } from './product-view/size-guide/size-guide.component';
@NgModule({
    declarations: [

        ProductsComponent,
        UpdateProductComponent,
        ProductViewComponent,
        CategoriesComponent,
        ProductImageComponent,
        ShareProductComponent,
        ShowHomeComponent,
        SizeGuideComponent,
    ],
    imports: [
        CommonModule,
        AllProductsRoutingModule,
        FormsModule,
        TranslateModule,
        LightboxModule,
        NgxSpinnerModule,
        NgbModule
    ]
})
export class AllProductsModule { }
