import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from 'src/app/services/cart/cart.service';
import { environment } from 'src/environments/environment.prod';

@Component({
    selector: 'app-show-home',
    templateUrl: './show-home.component.html',
    styleUrls: ['./show-home.component.scss']
})
export class ShowHomeComponent implements OnInit {
    imgBase = environment.imgURL;
    @Input() productData = {} as any;
    @Input() selectedSubProduct = {} as any;

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    constructor(
        private cartService: CartService,

        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {
    }



    AddToBag() {
        let date = {
            carancy: this.selectedSubProduct['currency_id'],
            product_id: this.productData['id'],
            sub_product_id: this.selectedSubProduct['id'],
            size_id: this.selectedSubProduct.size.size,
            price_id: Number(this.productData['show_at_home_price']),
            note: '',
            quantity: 1
        };
        this.cartService.showAtHome(date).subscribe((res) => {
            this.cartService.cartDetails();
            this.passEntry.emit(true);
        });
    }
}
